import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PlayerChip from "../../../../component/PlayerChip";
import getPlayerPickerPlayers from "../../../../queries/player/getPlayerPickerPlayers";
import PlayerPickerPlayer from "../../../../types/Player/PlayerPickerPlayer";

interface IPlayerPickerProps {
  selected: Set<number>;
  setSelected: Dispatch<SetStateAction<Set<number>>>;
  locked: Set<number>;
  setLocked: Dispatch<SetStateAction<Set<number>>>;
}

const PlayerPicker: FC<IPlayerPickerProps> = ({
  selected,
  setSelected,
  locked,
  setLocked,
}) => {
  const { data: allPlayers, isLoading } = useQuery(
    "player-picker",
    getPlayerPickerPlayers
  );

  const [searchName, setSearchName] = useState("");
  const searchNameRef = useRef<HTMLInputElement>();

  const navigate = useNavigate();

  const handleClick = (player: PlayerPickerPlayer) => {
    const pId = player.id;

    if (selected.has(pId)) {
      setSelected((prev) => {
        const next = new Set(prev);
        next.delete(pId);
        return next;
      });
      setLocked((prev) => new Set(prev).add(pId));
    } else if (locked.has(pId)) {
      setLocked((prev) => {
        const next = new Set(prev);
        next.delete(pId);
        return next;
      });
    } else {
      setSelected((prev) => new Set(prev).add(pId));
    }
  };

  const filteredPlayers = allPlayers?.filter((p) =>
    p.name.toLowerCase().includes(searchName.toLowerCase())
  );

  if (isLoading) return <CircularProgress />;

  if (allPlayers?.length === 0)
    return (
      <Stack>
        <Typography variant="h6">
          Press the button in the bottom right corner of the player page to add
          your first player
        </Typography>
        <Button color="primary" onClick={() => navigate("/players")}>
          Go to player page
        </Button>
      </Stack>
    );

  return (
    <Stack width="98%" marginTop={2}>
      <TextField
        label="Search by name"
        value={searchName}
        onChange={(event) => {
          setSearchName(event.target.value);
        }}
        fullWidth
        sx={{ maxWidth: "400px" }}
        inputRef={searchNameRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => {
                  setSearchName("");
                  searchNameRef.current?.focus();
                }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={1} sx={{ paddingRight: "8px" }}>
        {filteredPlayers?.map((player) => (
          <Grid item xs={4} key={player.id}>
            <PlayerChip
              player={player}
              handleClick={handleClick}
              isSelected={selected.has(player.id)}
              isLocked={locked.has(player.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default PlayerPicker;
