import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { PlayerAchievement } from "../../../../../types/Achievement/PlayerAchievement";

const PlayerProfileAchievementCard: FC<{
  playerAchievement: PlayerAchievement;
}> = ({ playerAchievement }) => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardContent sx={{ textAlign: "center" }}>
        <Typography variant="h5">
          {playerAchievement.achievement?.displayName}
        </Typography>
        <Typography variant="body2">
          {playerAchievement.achievement?.description}
        </Typography>
        <Stack
          sx={{ marginTop: 2 }}
          direction="row"
          spacing={1}
          justifyContent="space-around"
        >
          {playerAchievement.achievement?.canObtainMultiple && (
            <Typography variant="body1">{playerAchievement.count}</Typography>
          )}
          {playerAchievement.achievement?.tiers?.map((t) => (
            <Box>
              {playerAchievement.count >= t ? (
                <Typography variant="body1" fontWeight="bold">
                  {t}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ opacity: 0.5 }}>
                  ?
                </Typography>
              )}
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PlayerProfileAchievementCard;
