import { Button } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import useLandingPageStyles from "./style";

const LandingPage: FC = () => {
  const navigate = useNavigate();
  const classes = useLandingPageStyles();

  return (
    <div className={classes.root}>
      <img className={classes.img} src="Foospower.png" alt="Foospower logo" />
      <h1>Welcome to Foospower!</h1>
      <Button variant="contained" onClick={() => navigate("/login")}>
        Login
      </Button>
      <Button variant="outlined" onClick={() => navigate("/register")}>
        Register
      </Button>
    </div>
  );
};

export default LandingPage;
