import { Button, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import logout from "../../queries/authentication/logout";

const LogoutButton: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { breakpoints } = useTheme();
  const largeScreen = useMediaQuery(breakpoints.up("md"));

  const handleLogout = async () => {
    await logout();
    queryClient.invalidateQueries();
    navigate("/login");
  };

  return (
    <Button
      color="error"
      onClick={handleLogout}
      sx={{ width: largeScreen ? "20vw" : "80vw", alignSelf: "center" }}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
