import PlayerProfile from "../../types/Player/PlayerProfile";
import http from "../../utils/http";

const getPlayerProfile = async (
  id: number,
  season: number = -1
): Promise<PlayerProfile> => {
  const res = await http.get("player/profile", { params: { id, season } });
  return res.data;
};

export default getPlayerProfile;
