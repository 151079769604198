import {
  PaginationRequest,
  PaginationResponse,
} from "../../dto/Pagination.dto";
import Game from "../../types/Game/Game";
import http from "../../utils/http";

const getAllGames = async (): Promise<Game[]> => {
  const res = await http.get("game");
  return res.data;
};

export const getAllGamesPaged = async (
  req: PaginationRequest
): Promise<PaginationResponse<Game>> => {
  const res = await http.get("game/paged", { params: req });
  return res.data;
};

export default getAllGames;
