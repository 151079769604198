import { CircularProgress } from "@mui/material";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import SeasonSelector from "../../../component/SeasonSelectors/SeasonSelector";
import getPlayerProfile from "../../../queries/player/getPlayerProfile";
import InformationBar from "./InformationBar";
import PlayerProfileAchievements from "./PlayerProfileAchievements";
import PlayerProfileGames from "./PlayerProfileGames";
import PlayerProfileHeader from "./PlayerProfileHeader";
import RatingChart from "./RatingChart";
import usePlayerProfileStyles from "./style";
import UpdateOrDeletePlayer from "./UpdateOrDeletePlayer";

const PlayerProfile: FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = usePlayerProfileStyles();
  const [season, setSeason] = useState(-1);

  const { data: player, isLoading } = useQuery(
    ["player-profile", id, season],
    () => {
      if (id) {
        return getPlayerProfile(parseInt(id), season);
      }
    },
    {
      onSuccess: (data) => {
        if (season === -1 && data) {
          setSeason(data.maxSeason);
        }
      },
    }
  );

  if (isLoading || !player) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <PlayerProfileHeader player={player} />
      <div className={classes.information}>
        <InformationBar player={player} />
        <RatingChart player={player} />
        <PlayerProfileAchievements playerAchievements={player.achievements} />
        <PlayerProfileGames player={player} />
        <SeasonSelector
          season={season}
          setSeason={setSeason}
          maxSeason={player.maxSeason}
        />
        <UpdateOrDeletePlayer player={player} />
      </div>
    </div>
  );
};

export default PlayerProfile;
