import { FC } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PlayerProfile from "../../../../types/Player/PlayerProfile";
import useRatingChartStyles from "./style";

const RatingChart: FC<{ player: PlayerProfile }> = ({ player }) => {
  const classes = useRatingChartStyles();
  const data = player.games.map((game) => ({
    created: game.created,
    offense: game.isOffense ? game.rating : null,
    defense: game.isDefense ? game.rating : null,
    solo: game.isSolo ? game.rating : null,
  }));

  data.reverse();

  const allRatings = data
    .map((game) => game.offense)
    .concat(data.map((game) => game.defense))
    .filter(Number) as number[];
  const lower = Math.round(Math.min(...allRatings) * 0.995);
  const upper = Math.round(Math.max(...allRatings) * 1.005);

  const formatDateTick = (date: string) =>
    new Date(date).toLocaleString(undefined, {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      weekday: "short",
      month: "short",
      day: "numeric",
    });

  return (
    <div className={classes.root}>
      <h2 className={classes.heading}>
        Rating Last {player.games.length} Games
      </h2>
      <ResponsiveContainer height={300} width="100%">
        <LineChart data={data} margin={{ right: 15 }}>
          <YAxis type="number" domain={[lower, upper]} />
          <XAxis dataKey="created" tick={false} />
          <Line
            name="Offense"
            type="monotone"
            dataKey="offense"
            stroke="#8884d8"
            connectNulls
          />
          <Line
            name="Defense"
            type="monotone"
            dataKey="defense"
            stroke="#82ca9d"
            connectNulls
          />
          <Line
            name="1v1"
            type="monotone"
            dataKey="solo"
            stroke="#721422"
            connectNulls
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Legend />
          <Tooltip labelFormatter={formatDateTick} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RatingChart;
