import { makeStyles } from "@mui/styles";

const useSendSlackMessageStyles = makeStyles(({ spacing }) => ({
  fab: {
    position: "absolute",
    bottom: spacing(10),
    right: spacing(2),
  },
}));

export default useSendSlackMessageStyles;
