import http from "../../utils/http";

const downgradeToFreeTier = async (): Promise<boolean> => {
  try {
    await http.put("organization/downgrade-to-free-tier");
  } catch (err) {
    return false;
  }
  return true;
};

export default downgradeToFreeTier;
