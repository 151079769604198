import { CircularProgress, Stack, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import http from "../../utils/http";

const StripeSuccess: FC = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const navigate = useNavigate();

  useEffect(() => {
    http.get("/Stripe/payment-success?sessionId=" + sessionId).then(() => {
      navigate("/home");
    });
  }, [navigate, sessionId]);

  return (
    <Stack alignItems="center" spacing={3} sx={{ marginTop: "40px" }}>
      <Typography variant="h3">Thank you!</Typography>
      <CircularProgress />
      <Typography variant="h4">We are processing your payment.</Typography>
      <Typography variant="h4">Stay put.</Typography>
    </Stack>
  );
};

export default StripeSuccess;
