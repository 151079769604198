import { Button } from "@mui/material";
import { FC } from "react";
import http from "../../../../utils/http";

const GoToStripeCustomerPortal: FC = () => {
  const onClick = async () => {
    const res = await http.get("/Stripe/customer-portal");
    window.location.href = res.data;
  };

  return <Button onClick={onClick}>Update or cancel subscription</Button>;
};

export default GoToStripeCustomerPortal;
