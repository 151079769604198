import makeStyles from '@mui/styles/makeStyles';

const useCreateOrganizationStyle = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
}));

export default useCreateOrganizationStyle;
