import makeStyles from "@mui/styles/makeStyles";

const useRegisterStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    marginTop: "50px",
  },
});

export default useRegisterStyles;
