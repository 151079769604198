import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import LoadingButton from "../../../../component/LoadingButton";
import startNewSeason from "../../../../mutations/organization/startNewSeason";

const StartNewSeasonDialog: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const startNewSeasonMutation = useMutation(startNewSeason);
  const queryClient = useQueryClient();

  const handleStartNewSeason = () => {
    setLoading(true);
    startNewSeasonMutation.mutate(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries("current-user");
        setLoading(false);
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Are you sure you would like to start a new season?
      </DialogTitle>
      <DialogContent>
        Starting a new season will reset all stats for all players. You will
        still be able to see the previous seasons in the player profile or in
        the player list.
        <br />
        <b>You will not be able to go back and play in the current season</b>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          color="error"
          onClick={handleStartNewSeason}
        >
          Start new season
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StartNewSeasonDialog;
