import makeStyles from "@mui/styles/makeStyles";

const usePregameStatsStyles = makeStyles(() => ({
  score: {
    display: "flex",
    justifyContent: "center",
    fontSize: "25px",
    fontWeight: 600,
    alignItems: "center",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    marginTop: "28px",
    width: "95%",
    margin: "auto",
    "& button": {
      flexGrow: 1,
    },
  },
}));

export default usePregameStatsStyles;
