import http from "../../utils/http";

const startNewSeason = async (): Promise<boolean> => {
  try {
    await http.post("organization/start-new-season");
  } catch (err) {
    return false;
  }
  return true;
};

export default startNewSeason;
