import { makeStyles } from "@mui/styles";

const useGameCardStyle = makeStyles(({ palette }) => ({
  root: {
    width: "100%",
  },
  card: {
    padding: 0,
    border: "3px solid transparent", //In case of perfect game border
  },
  cardContent: {
    display: "flex",
    alignContent: "center",
  },
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& p": {
      margin: 0,
      lineHeight: "16px",
    },
  },
  teamSpan: {
    display: "inline-flex",
    alignItems: "center",
  },
  blackTeamSpan: {
    justifyContent: "flex-end",
  },
  roleIcons: {
    height: "16px",
    opacity: 0.75,
    padding: "0 5px 0 5px",
  },
  teamWrapper: {
    width: "42.5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  blackTeamWrapper: {
    textAlign: "right",
  },
  scoreWrapper: {
    width: "15%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  time: {
    textAlign: "center",
  },
  perfectGame: {
    border: "3px solid " + palette.primary.main,
  },
}));

export default useGameCardStyle;
