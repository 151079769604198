import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import getAllPlayers from "../../../queries/player/getAllPlayers";
import DeleteIcon from "@mui/icons-material/Delete";
import deletePlayer from "../../../mutations/player/deletePlayer";
import downgradeToFreeTier from "../../../mutations/organization/downgradeToFreeTier";
import { useNavigate } from "react-router-dom";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";

const DeletePlayersToDowngradeToFreeTier = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showMessage } = useGlobalSnackbar();

  const { data: allPlayers, isLoading } = useQuery("all-players", () =>
    getAllPlayers()
  );

  const handleDelete = async (playerId: number) => {
    await deletePlayer(playerId);
    queryClient.invalidateQueries("all-players");
  };

  const handleDowngrade = async () => {
    const success = await downgradeToFreeTier();
    if (success) {
      navigate("/home");
    } else {
      showMessage(
        "Something went wrong when trying to downgrade your user. Please contact support.",
        "error"
      );
    }
  };

  return (
    <Stack>
      <Typography variant="h3">
        Free tier does not support more than 5 players.
      </Typography>
      <Typography variant="h4">
        Please delete players to downgrade to free tier.
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h5">
            You have {allPlayers?.length} players
          </Typography>
          <Button
            color="success"
            disabled={allPlayers && allPlayers?.length > 5}
            onClick={handleDowngrade}
          >
            Downgrade to free tier
          </Button>
          {allPlayers?.map((p) => (
            <Card>
              <Stack direction="row">
                <Avatar src={p.avatar} />
                <Typography variant="h5">{p.name}</Typography>
                <IconButton onClick={() => handleDelete(p.id)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Stack>
            </Card>
          ))}
        </>
      )}
    </Stack>
  );
};

export default DeletePlayersToDowngradeToFreeTier;
