import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      "Open Sans",
      "Arial",
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#1B8ECE",
    },
    secondary: {
      main: "#232F63",
    },
    warning: {
      main: "#FCB016",
      dark: "#D50000",
    },
    success: {
      main: "#31C59A",
      light: "#00C853",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: "center",
        spacing: 2,
      },
    },
  },
});

export default defaultTheme;
