import { FC } from "react";
import PlayerProfile from "../../../../types/Player/PlayerProfile";
import WinningStreak from "../../PlayerListCard/WinningStreak";
import usePlayerProfileHeaderStyles from "./style";

const PlayerProfileHeader: FC<{ player: PlayerProfile }> = ({ player }) => {
  const classes = usePlayerProfileHeaderStyles();
  const avatar = player?.avatar ? player?.avatar : "/userAvatar.png";

  return (
    <div className={classes.header}>
      <div className={classes.headerName}>
        <h1>{player.name}</h1>
        <div className={classes.streakWrapper}>
          <WinningStreak player={player} large />
        </div>
      </div>
      <img className={classes.avatar} src={avatar} alt="Profile" />
    </div>
  );
};

export default PlayerProfileHeader;
