import { Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import GameCard from "./GameCard";
import useSubscriptionTierGuard from "../../hooks/useSubcriptionTierGuard";
import deleteGame from "../../mutations/game/deleteGame";
import { getAllGamesPaged } from "../../queries/game/getAllGames";
import Game from "../../types/Game/Game";
import SubscriptionTier from "../../types/enums/SubscriptionTier";

const Games: FC = () => {
  var { refetch } = useQuery(
    "all-games",
    () => getAllGamesPaged({ pageIndex, pageSize }),
    {
      enabled: false, // Don't automatically refetch on every render
      onSuccess: (data) => {
        setGames((prev) => prev.concat(data.items) as Game[]);
        if (data.items.length < pageSize) {
          setHasMore(false);
        }
      },
    }
  );

  const [pageIndex, setPageIndex] = useState(0);
  const [games, setGames] = useState<Game[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 25;

  useEffect(() => {
    refetch();
  }, [pageIndex, pageSize, refetch]);

  const { currentUserHasTier } = useSubscriptionTierGuard();

  const handleDelete = (gameId: number) => {
    if (currentUserHasTier(SubscriptionTier.Premium, "delete games")) {
      deleteMutation.mutate(gameId);
    }
  };

  const deleteMutation = useMutation(deleteGame, {
    onSuccess: (id: number) => {
      setGames((prev) => prev.filter((g) => g.id !== id));
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          maxWidth: "100vw",
          overflowX: "auto",
          display: "inline-flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          rowGap: "1rem",
          padding: "1rem 0",
        }}
      >
        {games.map((game) => (
          <GameCard game={game} handleDelete={handleDelete} />
        ))}
      </div>
      <Button
        disabled={!hasMore}
        onClick={() => setPageIndex((prev) => prev + 1)}
      >
        {hasMore ? "Get More" : "There are no more games"}
      </Button>
    </div>
  );
};

export default Games;
