import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import useSubscriptionTierGuard from "../../hooks/useSubcriptionTierGuard";
import SubscriptionTier from "../../types/enums/SubscriptionTier";

const SubscriptionTierGuard: FC = () => {
  const { open, tier, feature, hideUpgradeModal } = useSubscriptionTierGuard();
  const navigate = useNavigate();

  const onUpgradeClick = () => {
    navigate("/select-subscription-tier");
    hideUpgradeModal();
  };

  return (
    <Dialog open={open} onClose={hideUpgradeModal}>
      <DialogTitle>You do not have access to this feature</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Get access to {feature} and more by upgrading to{" "}
          {SubscriptionTier[tier]}!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideUpgradeModal} color="error">
          Go back
        </Button>
        <Button onClick={onUpgradeClick} color="primary">
          Upgrade now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionTierGuard;
