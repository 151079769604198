import makeStyles from "@mui/styles/makeStyles";

const useLandingPageStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    marginTop: "50px",
  },
  img: {
    maxWidth: "80%",
  },
}));

export default useLandingPageStyles;
