import { ReactElement } from "react";
import Games from "../views/Games";
import Home from "../views/Home";
import InactiveSubscription from "../views/InactiveSubscription";
import DeletePlayersToDowngradeToFreeTier from "../views/InactiveSubscription/DeletePlayersToDowngradeToFreeTier";
import LandingPage from "../views/LandingPage";
import Login from "../views/Login";
import SelectOrganization from "../views/Login/SelectOrganization";
import Players from "../views/Players";
import PlayerProfile from "../views/Players/PlayerProfile";
import Register from "../views/Register";
import SelectSubscriptionTier from "../views/SelectSubscriptionTier";
import Settings from "../views/Settings";
import StripeSuccess from "../views/StripeSuccess";

interface IRoute {
  path: string;
  element: ReactElement;
  showMenu?: boolean;
}

export const publicRoutes = [
  { path: "/", element: <LandingPage /> },
  { path: "/register", element: <Register /> },
  { path: "/login", element: <Login /> },
  { path: "/select-organization", element: <SelectOrganization /> },
  { path: "/select-subscription-tier", element: <SelectSubscriptionTier /> },
  { path: "/stripe-success", element: <StripeSuccess /> },
  { path: "/inactive-subscription", element: <InactiveSubscription /> },
  {
    path: "/delete-players-to-downgrade-to-free-tier",
    element: <DeletePlayersToDowngradeToFreeTier />,
  },
];

export const restrictedRoutes: IRoute[] = [
  { path: "/home", element: <Home />, showMenu: true },
  { path: "/games", element: <Games />, showMenu: true },
  { path: "/players", element: <Players />, showMenu: true },
  { path: "/players/:id", element: <PlayerProfile />, showMenu: true },
  { path: "/settings", element: <Settings />, showMenu: true },
];
