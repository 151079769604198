import { CircularProgress, Fab, Grid, Stack, useTheme } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import CreatePlayer from "./CreatePlayer";
import PlayerCard from "./PlayerListCard";
import SeasonSelectorSpeedDial from "../../component/SeasonSelectors/SeasonSelectorSpeedDial";
import getCurrentUser from "../../queries/user/getCurrentUser";
import getPlayerList from "../../queries/player/getPlayerList";
import PlayerListDto from "../../types/Player/PlayerListDto";
import GameType from "../../types/enums/GameType";

const Players: FC = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [season, setSeason] = useState(-1);
  const [sortGameType, setSortGameType] = useState<GameType>(GameType.OneVsOne);

  const { spacing } = useTheme();

  const { data: allPlayers, isLoading } = useQuery(
    ["player-list", season],
    () => getPlayerList(season)
  );

  const { data: currentUser } = useQuery(
    ["current-user"],
    () => getCurrentUser(),
    {
      onSuccess: (data) => {
        if (season === -1 && data) {
          setSeason(data.currentOrganization.season);
        }

        if (data.currentOrganization.preferredGameTypes.length === 1)
          setSortGameType(data.currentOrganization.preferredGameTypes[0]);
      },
    }
  );

  const preferredGameTypes =
    currentUser?.currentOrganization.preferredGameTypes;
  const ratingSort1v1 = (a: PlayerListDto, b: PlayerListDto) =>
    b.current1v1Rating > a.current1v1Rating ? 1 : -1;
  const ratingSort2v2 = (a: PlayerListDto, b: PlayerListDto) =>
    b.averageRating > a.averageRating ? 1 : -1;

  const currentSort =
    sortGameType === GameType.OneVsOne ? ratingSort1v1 : ratingSort2v2;

  const isCurrentSeason = season === currentUser?.currentOrganization.season;

  return (
    <Grid container sx={{ padding: 2, marginBottom: "150px" }} spacing={2}>
      {isLoading && <CircularProgress />}
      {allPlayers
        ?.filter((p) => p.isActive || !isCurrentSeason)
        .sort(currentSort)
        .map((p, i) => (
          <PlayerCard
            player={p}
            index={i}
            key={p.id}
            activeOverride={!isCurrentSeason} // Make players look active if we are not in the current season
          />
        ))}
      {allPlayers
        ?.filter((p) => !p.isActive && isCurrentSeason)
        .sort(currentSort)
        .map((p, i) => (
          <PlayerCard player={p} key={p.id} />
        ))}
      <Stack
        direction="row-reverse"
        sx={{
          position: "fixed",
          bottom: spacing(9),
          right: spacing(1),
          height: "56px",
        }}
        spacing={0.5}
      >
        {isCurrentSeason && (
          <Fab color="secondary" onClick={() => setOpenCreate(true)}>
            <PersonAdd />
          </Fab>
        )}
        {preferredGameTypes && preferredGameTypes.length > 1 && (
          <Fab
            variant="extended"
            color="secondary"
            sx={{
              height: "56px",
            }}
            onClick={() => {
              setSortGameType((prev) =>
                prev === GameType.OneVsOne
                  ? GameType.TwoVsTwo
                  : GameType.OneVsOne
              );
            }}
          >
            Sort by {sortGameType === GameType.OneVsOne ? "2v2" : "1v1"} rating
          </Fab>
        )}
        <SeasonSelectorSpeedDial
          season={season}
          setSeason={setSeason}
          maxSeason={currentUser?.currentOrganization.season ?? 1}
        />
      </Stack>
      <CreatePlayer open={openCreate} setOpen={setOpenCreate} />
    </Grid>
  );
};

export default Players;
