import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { FC, FormEvent, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import LoadingButton from "../../../../../component/LoadingButton";
import useGlobalSnackbar from "../../../../../hooks/useGlobalSnackbar";
import updatePlayer from "../../../../../mutations/player/updatePlayer";
import PlayerProfile from "../../../../../types/Player/PlayerProfile";

interface IUpdatePlayerDialogProps {
  open: boolean;
  onClose: () => void;
  player: PlayerProfile;
}

const UpdatePlayerDialog: FC<IUpdatePlayerDialogProps> = ({
  open,
  onClose,
  player,
}) => {
  const [name, setName] = useState(player.name);
  const [avatar, setAvatar] = useState(player.avatar);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { showMessage } = useGlobalSnackbar();

  const updateMutation = useMutation(updatePlayer, {
    onSuccess: () => {
      onClose();
      showMessage("Player updated successfully");
      setLoading(false);
      queryClient.invalidateQueries("all-players");
      queryClient.invalidateQueries("player-" + player.id);
      queryClient.invalidateQueries("player-profile-" + player.id);
    },
    onError: () => {
      showMessage("Error updating player", "error");
      setLoading(false);
    },
  });

  const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    updateMutation.mutate({ id: player.id, name, avatar });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Player</DialogTitle>
      <form onSubmit={handleUpdate}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            paddingTop: "10px",
          }}
        >
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Avatar"
            value={avatar}
            onChange={(e) => setAvatar(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            type="submit"
          >
            Update
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdatePlayerDialog;
