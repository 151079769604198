import { AlertColor } from "@mui/material";
import { createContext, FC, useState } from "react";

export const SnackbarContext = createContext({
  open: false,
  message: "",
  severity: "success" as AlertColor,
  showMessage: (
    message: string,
    severity?: AlertColor,
    duration?: number
  ) => {},
  hideMessage: () => {},
  duration: 6000,
});

const SnackbarProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>("success");
  const [duration, setDuration] = useState(6000);

  const showMessage = (
    message: string,
    severity?: AlertColor,
    duration?: number
  ) => {
    setMessage(message);
    setSeverity(severity || "success");
    setOpen(true);
    setDuration(duration || 6000);
  };

  const hideMessage = () => setOpen(false);

  const context = {
    open,
    message,
    severity,
    showMessage,
    hideMessage,
    duration,
  };

  return (
    <SnackbarContext.Provider value={context}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
