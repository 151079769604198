import { Button, Stack } from "@mui/material";
import React from "react";
import { FC } from "react";
import { useQuery } from "react-query";
import PregameStats1v1Dto from "../../../../dto/PregameStats1v1.dto";
import PregameStats2v2Dto from "../../../../dto/PregameStats2v2.dto";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import getPregameStats1v1 from "../../../../queries/game/getPregameStas1v1";
import getPregameStats2v2 from "../../../../queries/game/getPregameStats2v2";
import getCurrentUser from "../../../../queries/user/getCurrentUser";
import GameType from "../../../../types/enums/GameType";

const StartGameButtons: FC<{
  selected: Set<number>;
  locked: Set<number>;
  setPregameStats1v1: (data: PregameStats1v1Dto) => void;
  setPregameStats2v2: (data: PregameStats2v2Dto) => void;
}> = ({ selected, locked, setPregameStats1v1, setPregameStats2v2 }) => {
  const { showMessage } = useGlobalSnackbar();
  const { data: user } = useQuery("current-user", getCurrentUser);
  const preferredGameTypes = user?.currentOrganization.preferredGameTypes;

  const pregameStats1v1 = useQuery(
    "pregame-stats-1v1",
    () => getPregameStats1v1(locked, selected),
    {
      enabled: false,
      retry: false,
      onSuccess: (result: PregameStats1v1Dto) => setPregameStats1v1(result),
    }
  );

  const pregameStats2v2 = useQuery(
    "pregame-stats-2v2",
    () => getPregameStats2v2(locked, selected),
    {
      enabled: false,
      retry: false,
      onSuccess: (result: PregameStats2v2Dto) => setPregameStats2v2(result),
    }
  );

  const handleStart1v1 = () => {
    if (locked.size + selected.size < 2) {
      showMessage("Please select at least 2 players", "error");
      return;
    }
    pregameStats1v1.refetch();
  };

  const handleStart2v2 = () => {
    if (locked.size + selected.size < 4) {
      showMessage("Please select at least 4 players", "error");
      return;
    }
    pregameStats2v2.refetch();
  };

  const show1v1Button = preferredGameTypes?.includes(GameType.OneVsOne);

  const show2v2Button = preferredGameTypes?.includes(GameType.TwoVsTwo);

  return (
    <Stack direction="row" marginTop={2}>
      {show1v1Button && (
        <Button
          color="primary"
          variant="contained"
          onClick={handleStart1v1}
          disabled={selected.size + locked.size < 2}
        >
          Start 1v1 Game
        </Button>
      )}
      {show2v2Button && (
        <Button
          color="primary"
          variant="contained"
          onClick={handleStart2v2}
          disabled={selected.size + locked.size < 4}
        >
          Start 2v2 Game
        </Button>
      )}
    </Stack>
  );
};

export default StartGameButtons;
