import { Grid } from "@mui/material";
import { FC } from "react";
import PlayerChip from "../../../../../component/PlayerChip";
import PregameStats1v1Dto from "../../../../../dto/PregameStats1v1.dto";
import Roles from "../../../../../types/enums/Roles";

const OneVsOnePregameGrid: FC<{ stats: PregameStats1v1Dto }> = ({ stats }) => {
  return (
    <>
      <Grid item xs={6}>
        <PlayerChip player={stats.blackPlayer} role={Roles.BlackSolo} />
      </Grid>
      <Grid item xs={6}>
        <PlayerChip player={stats.whitePlayer} role={Roles.WhiteSolo} />
      </Grid>
    </>
  );
};

export default OneVsOnePregameGrid;
