import { Card } from "@mui/material";
import { FC } from "react";
import useInformationBarCardStyles from "./style";

interface IInformationBarCardProps {
  title?: string;
  value?: string | number;
  backgroundSrc?: string;
}

const InformationBarCard: FC<IInformationBarCardProps> = ({ title, value }) => {
  const classes = useInformationBarCardStyles();
  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value}</div>
    </Card>
  );
};

export default InformationBarCard;
