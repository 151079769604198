import { makeStyles } from "@mui/styles";

const useInformationBarStyles = makeStyles(() => ({
  informationBar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    rowGap: "20px",
  },
}));

export default useInformationBarStyles;
