import { MenuItem, TextField } from "@mui/material";
import { ChangeEventHandler, FC } from "react";
import Player from "../../../../../types/Player/Player";

interface IPlayerSelectProps {
  label: string;
  players: Player[];
  player?: Player;
  selectPlayer: (player: Player) => void;
}

const PlayerSelect: FC<IPlayerSelectProps> = ({
  label,
  players,
  player,
  selectPlayer,
}) => {
  const setPlayer: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    const p = players.find((p) => p.id === parseInt(event.target.value));
    selectPlayer(p!);
  };

  return (
    <TextField
      fullWidth
      select
      label={label}
      value={player?.id}
      onChange={setPlayer}
    >
      {players.map((player) => (
        <MenuItem key={player.id} value={player.id}>
          {player.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default PlayerSelect;
