import { Card, CircularProgress, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useQuery } from "react-query";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import SubscriptionTier from "../../../types/enums/SubscriptionTier";
import GoToStripeCustomerPortal from "./GoToStripeCustomerPortal";
import RegisterSlackWebhook from "./RegisterSlackWebhook";

const OrganizationSettings: FC = () => {
  const { isLoading, data: user } = useQuery("current-user", getCurrentUser);

  const org = user?.currentOrganization;

  return (
    <Card>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Stack>
          <Typography variant="h5">{org?.name}</Typography>
          <Typography variant="h6">
            {org && SubscriptionTier[org?.subscriptionTier]} tier
          </Typography>
          <RegisterSlackWebhook />
          <GoToStripeCustomerPortal />
        </Stack>
      )}
    </Card>
  );
};

export default OrganizationSettings;
