import { Add, Pause, PlayArrow, Remove, Replay } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import useGameTimerStyles from "./style";

const GameTimer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(300);
  const [remainingTime, setRemainingTime] = useState<number | undefined>(300);
  const [key, setKey] = useState(0);
  const classes = useGameTimerStyles();

  const reset = () => {
    setKey(key + 1);
    setRemainingTime(duration);
  };

  const update = (seconds: number) => {
    setKey(key + 1);
    setDuration(duration + seconds);
    setRemainingTime(!!remainingTime ? remainingTime + seconds : 0);
  };

  return (
    <>
      <div className={classes.timerWrapper}>
        <IconButton color="secondary" onClick={() => update(-30)}>
          <Remove />
        </IconButton>
        <CountdownCircleTimer
          key={key}
          isPlaying={isPlaying}
          duration={duration}
          initialRemainingTime={remainingTime}
          colors="#1B8ECE"
        >
          {({ remainingTime }) => {
            setRemainingTime(remainingTime);
            return !!remainingTime ? (
              <Typography variant="h4">
                {Math.floor(remainingTime / 60) +
                  ":" +
                  (remainingTime % 60 < 10
                    ? "0" + (remainingTime % 60)
                    : remainingTime % 60)}
              </Typography>
            ) : (
              <Typography variant="h4">0:00</Typography>
            );
          }}
        </CountdownCircleTimer>
        <IconButton color="secondary" onClick={() => update(30)}>
          <Add />
        </IconButton>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.buttons}
          variant="contained"
          color="primary"
          onClick={reset}
        >
          <Replay />
        </Button>
        <Button
          className={classes.buttons}
          variant="contained"
          color="primary"
          onClick={() => setIsPlaying(!isPlaying)}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </Button>
      </div>
    </>
  );
};

export default GameTimer;
