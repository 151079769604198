import {
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from "@mui/material";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import GameType from "../../../types/enums/GameType";
import ManualGameDialog from "./ManualGameDialog";
import AddIcon from "@mui/icons-material/Add";

const ManualGame: FC = () => {
  const { spacing } = useTheme();

  const { data: user } = useQuery("current-user", getCurrentUser);
  const preferredGameTypes = user?.currentOrganization.preferredGameTypes;

  const [open, setOpen] = useState(false);
  const [gameType, setGameType] = useState(
    preferredGameTypes ? preferredGameTypes[0] : 0
  );

  const handleOpen = (gameType: GameType) => {
    setGameType(gameType);
    setOpen(true);
  };

  const buttonPosition = {
    position: "fixed",
    bottom: spacing(10),
    right: spacing(10),
  };

  if (preferredGameTypes)
    return (
      <>
        {preferredGameTypes.length > 1 ? (
          <SpeedDial
            ariaLabel="Add new game speed dial"
            icon={<SpeedDialIcon />}
            sx={buttonPosition}
          >
            {GameType.TwoVsTwo in preferredGameTypes && (
              <SpeedDialAction
                key={GameType.TwoVsTwo}
                icon="2v2"
                onClick={() => handleOpen(GameType.TwoVsTwo)}
                tooltipTitle="Two vs two"
              />
            )}
            {GameType.OneVsOne in preferredGameTypes && (
              <SpeedDialAction
                key={GameType.OneVsOne}
                icon="1v1"
                onClick={() => handleOpen(GameType.OneVsOne)}
                tooltipTitle="One vs one"
              />
            )}
          </SpeedDial>
        ) : (
          <>
            <Fab
              color="primary"
              sx={buttonPosition}
              onClick={() => handleOpen(preferredGameTypes[0])}
            >
              <AddIcon />
            </Fab>
          </>
        )}
        <ManualGameDialog open={open} setOpen={setOpen} gameType={gameType} />
      </>
    );

  return <></>;
};

export default ManualGame;
