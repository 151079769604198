import { FC } from "react";
import GameStarter from "./GameStarter";
import ManualGame from "./ManualGame";
import SendSlackMessage from "./SendSlackMessage";
import useHomeStyles from "./style";

const Home: FC = () => {
  const classes = useHomeStyles();

  return (
    <div className={classes.root}>
      <img src="/Foospower.png" alt="Foospower logo" className={classes.logo} />
      <GameStarter />
      <SendSlackMessage />
      <ManualGame />
    </div>
  );
};

export default Home;
