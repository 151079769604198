import { FC } from "react";
import PlayerProfile from "../../../../types/Player/PlayerProfile";
import InformationBarCard from "./InformationBarCard";
import useInformationBarStyles from "./style";

const InformationBar: FC<{ player: PlayerProfile }> = ({ player }) => {
  const classes = useInformationBarStyles();

  return (
    <div className={classes.informationBar}>
      <InformationBarCard title="Games" value={player.gamesPlayed} />
      <InformationBarCard
        title="Wins"
        value={player.wins + " - " + Math.round(player.winRate * 100) + "%"}
      />
      <InformationBarCard title="1v1" value={player.current1v1Rating} />
      <InformationBarCard title="Offense" value={player.currentOffenseRating} />
      <InformationBarCard title="Average" value={player.averageRating} />
      <InformationBarCard title="Defense" value={player.currentDefenseRating} />
    </div>
  );
};

export default InformationBar;
