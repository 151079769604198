import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import { FC } from "react";
import { PlayerAchievement } from "../../../../types/Achievement/PlayerAchievement";
import PlayerProfileAchievementCard from "./PlayerProfileAchievementCard";

const PlayerProfileAchievement: FC<{
  playerAchievements: PlayerAchievement[];
}> = ({ playerAchievements }) => {
  return (
    <Accordion sx={{ width: "100%" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Achievements
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {playerAchievements.map((playerAchievement) => (
            <PlayerProfileAchievementCard
              playerAchievement={playerAchievement}
              key={playerAchievement.id}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default PlayerProfileAchievement;
