import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import PlayerProfile from "../../../../types/Player/PlayerProfile";
import usePlayerProfileGamesStyle from "./style";
import { ReactComponent as Sword } from "../../../../assets/sword.svg";
import { ReactComponent as Shield } from "../../../../assets/shield.svg";
import moment from "moment";

const PlayerProfileGames: FC<{ player: PlayerProfile }> = ({ player }) => {
  const classes = usePlayerProfileGamesStyle();

  const { palette } = useTheme();

  return (
    <>
      <h2>Last {player.games.length} Games</h2>
      {player.games.map((game, index) => (
        <Card sx={{ width: "90%" }} key={index} elevation={5}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack sx={{ width: "42.5%" }} spacing={0} alignItems="start">
                {game.isSolo ? (
                  <Typography variant="body1" fontWeight={600}>
                    {player.name}
                  </Typography>
                ) : (
                  <>
                    <Stack direction="row">
                      <Sword className={classes.roleIcons} />
                      <Typography
                        sx={{ fontWeight: game.isOffense ? 600 : 400 }}
                      >
                        {!game.isOffense ? game.teammate : player.name}
                      </Typography>
                    </Stack>
                    <Stack direction="row">
                      <Shield className={classes.roleIcons} />
                      <Typography
                        sx={{ fontWeight: !game.isOffense ? 600 : 400 }}
                      >
                        {game.isOffense ? game.teammate : player.name}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
              <Stack
                color={
                  game.ratingChange > 0
                    ? palette.success.light
                    : palette.warning.dark
                }
                spacing={0}
              >
                <Typography>{game.score}</Typography>
                <Typography>{game.ratingChange}</Typography>
              </Stack>
              <Stack sx={{ width: "42.5%" }} spacing={0} alignItems="end">
                {game.isSolo ? (
                  <Typography variant="body1">{game.soloOpponent}</Typography>
                ) : (
                  <>
                    <Stack direction="row">
                      <Typography>{game.opponentOffense}</Typography>
                      <Sword className={classes.roleIcons} />
                    </Stack>
                    <Stack direction="row">
                      <Typography>{game.opponentDefense}</Typography>
                      <Shield className={classes.roleIcons} />
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
            <Typography sx={{ textAlign: "center" }}>
              {moment.utc(game.created).local().fromNow()}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default PlayerProfileGames;
