import axios, { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";

const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
});

export const setupInterceptors = (navigate: NavigateFunction) => {
  http.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: any) => {
      if (error.response.status === 401) {
        console.error("Unauthorized");
        navigate("/login");
      }
      return Promise.reject(error);
    }
  );
};

export default http;
