import { makeStyles } from "@mui/styles";

const useInformationBarCardStyles = makeStyles(() => ({
  card: {
    width: "28%",
    height: "110px",
    position: "relative",
  },
  title: {
    margin: "10px 0 0 10px",
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  value: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
}));

export default useInformationBarCardStyles;
