import { makeStyles } from "@mui/styles";

const useRatingChartStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    marginLeft: "20px",
  },
}));

export default useRatingChartStyles;
