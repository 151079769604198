import { FC } from "react";
import { useQuery } from "react-query";
import { Outlet, useNavigate } from "react-router-dom";
import getCurrentUser from "../../queries/user/getCurrentUser";
import SubscriptionTier from "../../types/enums/SubscriptionTier";

const LoggedInRouteGuard: FC = () => {
  const navigate = useNavigate();

  useQuery("current-user", getCurrentUser, {
    onSuccess: (user) => {
      const tierNotChosen =
        user?.currentOrganization?.subscriptionTier ===
        SubscriptionTier.NotChosen;

      const subscriptionInactive =
        !user?.currentOrganization?.hasActiveSubscription;

      if (tierNotChosen) navigate("/select-subscription-tier");

      if (subscriptionInactive) navigate("/inactive-subscription");

      if (!user?.currentOrganization) navigate("/select-organization");
    },
  });

  return <Outlet />;
};

export default LoggedInRouteGuard;
