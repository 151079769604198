import Create1v1GameDto from "../../dto/Create1v1Game.dto";
import Game from "../../types/Game/Game";
import http from "../../utils/http";

const create1v1Game = async (
  createGameDto: Create1v1GameDto
): Promise<Game> => {
  const res = await http.post("/game/1v1", createGameDto);
  return res.data;
};

export default create1v1Game;
