import makeStyles from "@mui/styles/makeStyles";

const useHomeStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginTop: "30px",
    width: "500px",
    maxWidth: "80vw",
  },
}));

export default useHomeStyles;
