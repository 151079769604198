import { Card, CardContent, CircularProgress, Typography } from "@mui/material";
import { FC } from "react";
import { useQuery } from "react-query";
import getCurrentUser from "../../../queries/user/getCurrentUser";

const UserSettings: FC = () => {
  const { isLoading, data: user } = useQuery("current-user", getCurrentUser);

  return (
    <Card>
      <CardContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h5">Username: {user?.userName}</Typography>
            <Typography variant="h6">E-mail: {user?.email}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default UserSettings;
