import { Button } from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import deletePlayer from "../../../../mutations/player/deletePlayer";
import PlayerProfile from "../../../../types/Player/PlayerProfile";
import UpdatePlayerDialog from "./UpdatePlayerDialog";

const UpdateOrDeletePlayer: FC<{ player: PlayerProfile }> = ({ player }) => {
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const navigate = useNavigate();

  const toggleUpdateDialog = () => setOpenUpdateDialog(!openUpdateDialog);

  const handleDelete = async (playerId: number) => {
    await deletePlayer(player.id);
    navigate("/players");
  };

  return (
    <>
      <Button
        onClick={toggleUpdateDialog}
        variant="contained"
        color="primary"
        style={{ width: "70%" }}
      >
        Update
      </Button>
      <Button
        variant="contained"
        color="error"
        style={{ width: "70%" }}
        onClick={() => handleDelete(player.id)}
      >
        Delete
      </Button>
      <UpdatePlayerDialog
        open={openUpdateDialog}
        onClose={toggleUpdateDialog}
        player={player}
      />
    </>
  );
};

export default UpdateOrDeletePlayer;
