import { StarBorderOutlined } from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FC } from "react";

const SubscriptionBenefit: FC<{ text: string }> = ({ text }) => {
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <StarBorderOutlined />
        </ListItemIcon>
        <ListItemText>{text}</ListItemText>
      </ListItem>
    </>
  );
};

export default SubscriptionBenefit;
