import qs from "qs";
import PregameStats2v2Dto from "../../dto/PregameStats2v2.dto";
import http from "../../utils/http";

const getPregameStats2v2 = async (
  locked: Set<number>,
  selected: Set<number>
): Promise<PregameStats2v2Dto> => {
  const res = await http.get("game/pregame-stats/2v2", {
    params: {
      lockedPlayerIds: Array.from(locked),
      playerIds: Array.from(selected),
    },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return res.data;
};

export default getPregameStats2v2;
