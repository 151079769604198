import {
  Avatar,
  Grid,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { ReactComponent as Sword } from "../../../assets/sword.svg";
import { ReactComponent as Shield } from "../../../assets/shield.svg";
import WinningStreak from "./WinningStreak";
import { useNavigate } from "react-router-dom";
import useSubscriptionTierGuard from "../../../hooks/useSubcriptionTierGuard";
import SubscriptionTier from "../../../types/enums/SubscriptionTier";
import PlayerListDto from "../../../types/Player/PlayerListDto";
import { useQuery } from "react-query";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import GameType from "../../../types/enums/GameType";

interface IPlayerListCardProps {
  player: PlayerListDto;
  index?: number;
  activeOverride?: boolean;
}

const PlayerListCard: FC<IPlayerListCardProps> = ({
  player,
  index,
  activeOverride,
}) => {
  const navigate = useNavigate();
  const { currentUserHasTier } = useSubscriptionTierGuard();
  const { palette } = useTheme();

  const { data: user } = useQuery("current-user", getCurrentUser);
  const preferredGameTypes = user?.currentOrganization.preferredGameTypes;

  const handleClick = () => {
    if (currentUserHasTier(SubscriptionTier.Premium, "player profiles")) {
      navigate(`/players/${player.id}`);
    }
  };

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Paper
        key={player.id}
        sx={{
          opacity: () => (player.isActive || activeOverride ? 1 : 0.5),
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ padding: "24px", paddingTop: "12px" }}
        >
          <Stack direction="row">
            <Avatar src={player.avatar} />
            <Typography variant="h5">{player.name}</Typography>
            <WinningStreak player={player} />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent={
            preferredGameTypes!.length > 1 ? "space-between" : "end"
          }
          color={palette.grey[600]}
          sx={{
            padding: "4px 24px",
          }}
        >
          {preferredGameTypes?.includes(GameType.OneVsOne) && (
            <Stack spacing={0}>
              <Typography sx={{ fontSize: 18, lineHeight: "24px" }}>
                1v1
              </Typography>
              <Typography variant="h6">{player.current1v1Rating}</Typography>
            </Stack>
          )}
          {preferredGameTypes?.includes(GameType.TwoVsTwo) && (
            <Stack direction="row">
              <Stack spacing={0}>
                <SvgIcon>
                  <Sword />
                </SvgIcon>
                <Typography variant="h6">
                  {player.currentOffenseRating}
                </Typography>
              </Stack>
              {index !== undefined && (
                <Stack spacing={0}>
                  <Typography sx={{ fontSize: 18, lineHeight: "24px" }}>
                    2v2
                  </Typography>
                  <Typography variant="h6">{player.averageRating}</Typography>
                </Stack>
              )}
              <Stack spacing={0}>
                <SvgIcon>
                  <Shield />
                </SvgIcon>
                <Typography variant="h6">
                  {player.currentDefenseRating}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Paper>
    </Grid>
  );
};

export default PlayerListCard;
