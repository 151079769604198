import { FC, FormEvent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import useManualGameStyles from "./style";
import PlayerSelect from "./PlayerSelect";
import { useMutation, useQuery } from "react-query";
import Player from "../../../../types/Player/Player";
import ScorePicker from "../../../../component/ScorePicker";
import useGlobalSnackbar from "../../../../hooks/useGlobalSnackbar";
import LoadingButton from "../../../../component/LoadingButton";
import Game, { getGameResultString } from "../../../../types/Game/Game";
import getMinimalPlayers from "../../../../queries/player/getMinimalPlayers";
import GameType from "../../../../types/enums/GameType";
import create2v2Game from "../../../../mutations/game/create2v2Game";
import create1v1Game from "../../../../mutations/game/create1v1Game";

const ManualGameDialog: FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  gameType: GameType;
}> = ({ open, setOpen, gameType }) => {
  const [tab, setTab] = useState("players");

  const classes = useManualGameStyles();

  const { data: allPlayers } = useQuery("minmal-players", getMinimalPlayers);

  // For 1v1
  const [blackPlayer, setBlackPlayer] = useState<Player>();
  const [whitePlayer, setWhitePlayer] = useState<Player>();

  // For 2v2
  const [blackOffense, setBlackOffense] = useState<Player>();
  const [blackDefense, setBlackDefense] = useState<Player>();
  const [whiteOffense, setWhiteOffense] = useState<Player>();
  const [whiteDefense, setWhiteDefense] = useState<Player>();

  const [whiteScore, setWhiteScore] = useState(0);
  const [blackScore, setBlackScore] = useState(0);

  const { showMessage } = useGlobalSnackbar();
  const checkIfValidPlayers = () => {
    let ids: number[] = [];
    if (gameType === GameType.TwoVsTwo) {
      if (!blackOffense || !blackDefense || !whiteOffense || !whiteDefense) {
        showMessage("Please select all players", "error");
        return false;
      }
      ids = [
        blackOffense.id,
        blackDefense.id,
        whiteOffense.id,
        whiteDefense.id,
      ];
    } else if (gameType === GameType.OneVsOne) {
      if (!blackPlayer || !whitePlayer) {
        showMessage("Please select both players", "error");
        return false;
      }
      ids = [blackPlayer.id, whitePlayer.id];
    }

    if (new Set(ids).size !== ids.length) {
      showMessage("Please select no duplicate players", "error");
      return false;
    }

    return true;
  };

  const [loading, setLoading] = useState(false);
  const create1v1GameMutation = useMutation(create1v1Game);
  const create2v2GameMutation = useMutation(create2v2Game);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    if (gameType === GameType.OneVsOne) {
      create1v1GameMutation.mutate(
        {
          blackScore,
          whiteScore,
          blackPlayerId: blackPlayer!.id,
          whitePlayerId: whitePlayer!.id,
        },
        {
          onSuccess,
        }
      );
    }
    create2v2GameMutation.mutate(
      {
        blackScore,
        whiteScore,
        blackDefenseId: blackDefense!.id,
        whiteDefenseId: whiteDefense!.id,
        blackOffenseId: blackOffense!.id,
        whiteOffenseId: whiteOffense!.id,
      },
      {
        onSuccess,
      }
    );
  };

  const onSuccess = (data: Game) => {
    setLoading(false);
    onClose();
    showMessage(getGameResultString(data), "success", 15000);
  };

  if (!allPlayers) {
    return null;
  }

  const onClose = () => {
    setOpen(false);
    setTab("players");
    setBlackOffense(undefined);
    setBlackDefense(undefined);
    setWhiteOffense(undefined);
    setWhiteDefense(undefined);
    setBlackPlayer(undefined);
    setWhitePlayer(undefined);
    setWhiteScore(0);
    setBlackScore(0);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          {tab === "players" && (
            <>
              <Typography variant="h5">Select Players</Typography>
              <PlayerSelect
                label={
                  gameType === GameType.TwoVsTwo
                    ? "Black Offense"
                    : "Black Player"
                }
                players={allPlayers}
                player={
                  gameType === GameType.TwoVsTwo ? blackOffense : blackPlayer
                }
                selectPlayer={
                  gameType === GameType.TwoVsTwo
                    ? setBlackOffense
                    : setBlackPlayer
                }
              />
              <PlayerSelect
                label={
                  gameType === GameType.TwoVsTwo
                    ? "Black Defense"
                    : "White Player"
                }
                players={allPlayers}
                player={
                  gameType === GameType.TwoVsTwo ? blackDefense : whitePlayer
                }
                selectPlayer={
                  gameType === GameType.TwoVsTwo
                    ? setBlackDefense
                    : setWhitePlayer
                }
              />
              {gameType === GameType.TwoVsTwo && (
                <>
                  <PlayerSelect
                    label={`White Offense`}
                    players={allPlayers}
                    player={whiteOffense}
                    selectPlayer={setWhiteOffense}
                  />
                  <PlayerSelect
                    label={`White Defense`}
                    players={allPlayers}
                    player={whiteDefense}
                    selectPlayer={setWhiteDefense}
                  />
                </>
              )}
            </>
          )}
          {tab === "score" && (
            <ScorePicker
              whiteScore={whiteScore}
              blackScore={blackScore}
              setWhiteScore={setWhiteScore}
              setBlackScore={setBlackScore}
              blackTeam={
                gameType === GameType.OneVsOne
                  ? [blackPlayer?.name]
                  : [blackOffense?.name, blackDefense?.name]
              }
              whiteTeam={
                gameType === GameType.OneVsOne
                  ? [whitePlayer?.name]
                  : [whiteOffense?.name, whiteDefense?.name]
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          {tab === "players" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => checkIfValidPlayers() && setTab("score")}
            >
              Next
            </Button>
          )}
          {tab === "score" && (
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ManualGameDialog;
