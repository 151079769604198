import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { FC } from "react";
import useGlobalSnackbar from "../../hooks/useGlobalSnackbar";

const GlobalSnackbar: FC = () => {
  const { open, message, severity, hideMessage, duration } =
    useGlobalSnackbar();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={duration}
      onClose={hideMessage}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
