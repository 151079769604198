import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  appBar: {
    position: "fixed",
    top: "auto",
    bottom: 0,
    height: 64,
    "& .MuiTabs-flexContainer": {
      height: 64,
    },
  },
}));

export default useStyles;
