import {
  useMediaQuery,
  Card,
  Typography,
  List,
  Button,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { FC } from "react";
import { useQuery } from "react-query";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import SubscriptionTier from "../../../types/enums/SubscriptionTier";
import http from "../../../utils/http";
import SubscriptionBenefit from "./SubscriptionBenefit";

interface ISubscriptionTierCardProps {
  tier: SubscriptionTier;
  price: number[];
  benefits: string[];
  yearly?: boolean;
}

const SubscriptionTierCard: FC<ISubscriptionTierCardProps> = ({
  tier,
  price,
  benefits,
  yearly,
}) => {
  const { breakpoints } = useTheme();
  const largeScreen = useMediaQuery(breakpoints.up("md"));
  const { data: user } = useQuery("current-user", getCurrentUser);

  const currentTier = user?.currentOrganization?.subscriptionTier;

  const getButtonText = () => {
    if (!currentTier) return "Select";

    if (currentTier === tier) return "Current";

    if (currentTier > tier) return "Downgrade";

    return "Upgrade";
  };

  const redirectToStripe = async () => {
    const response = await http.post("/Stripe/create-session", {
      tier,
      yearly,
    });
    window.location.href = response.data;
  };

  return (
    <Card sx={{ width: largeScreen ? "30%" : "100%" }}>
      <Stack justifyContent="space-between" height="100%">
        <Stack>
          <Typography variant={largeScreen ? "h4" : "h5"}>
            {SubscriptionTier[tier]}
          </Typography>
          <Typography variant={largeScreen ? "h3" : "h4"}>
            {yearly ? price[1] : price[0]} NOK{" "}
            <span
              style={{ fontSize: "0.5em", fontWeight: 500, opacity: "0.75" }}
            >
              per {yearly ? "year" : "month"}
            </span>
          </Typography>
          <List>
            {benefits.map((benefit) => (
              <SubscriptionBenefit text={benefit} />
            ))}
          </List>
        </Stack>
        <Button disabled={currentTier === tier} onClick={redirectToStripe}>
          {getButtonText()}
        </Button>
      </Stack>
    </Card>
  );
};

export default SubscriptionTierCard;
