import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface IPlayerChipStyleProps {
  teamIsSelected: boolean;
  isSelected?: boolean;
  isLocked?: boolean;
}

const usePlayerChipStyles = makeStyles<Theme, IPlayerChipStyleProps>(
  ({ palette }) => ({
    chip: {
      width: "100%",
      borderRadius: "7px",
      justifyContent: "flex-start",
      backgroundColor: ({ isSelected, isLocked }) =>
        isSelected
          ? palette.primary.dark
          : isLocked
          ? palette.warning.main
          : "",
      "&:focus, &:hover": {
        backgroundColor: ({ isSelected, isLocked }) =>
          isSelected
            ? palette.primary.dark
            : isLocked
            ? palette.warning.main
            : "",
      },
      color: ({ isSelected }) => (isSelected ? "#fff" : ""),
      "& .MuiChip-label": {
        width: ({ teamIsSelected }) => (teamIsSelected ? "100%" : " auto"),
      },
    },
    selected: {
      color: "#fff",
      backgroundColor: palette.primary.dark,
      "&:focus, &:hover": {
        backgroundColor: palette.primary.dark,
      },
    },
    locked: {
      backgroundColor: palette.warning.main,
      "&:focus, &:hover": {
        backgroundColor: palette.warning.main,
      },
    },
    avatar: {
      background: "transparent",
      color: "#000",
    },
    avatarGameCount: {
      fontSize: "1rem",
      color: ({ isSelected }) => (isSelected ? "#fff" : ""),
    },
    includeRole: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
    },
  })
);

export default usePlayerChipStyles;
