import { Button, Stack, TextField, Typography, useTheme } from "@mui/material";
import { FC, FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../component/LoadingButton";
import useGlobalSnackbar from "../../hooks/useGlobalSnackbar";
import registerUser from "../../mutations/user/registerUser";
import useRegisterStyles from "./style";

const Register: FC = () => {
  const classes = useRegisterStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const registerUserMutation = useMutation(registerUser);
  const navigate = useNavigate();

  const { showMessage } = useGlobalSnackbar();

  const handleRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    registerUserMutation.mutate(
      {
        username,
        password,
        email,
      },
      {
        onSuccess: (data) => {
          showMessage(data.message);
          navigate("/login");
        },
        onError: (error: any) => {
          showMessage(error.response.data.message, "error");
          setShowErrors(true);
        },
      }
    );
  };

  const usernameError = username.length < 6 || username.length > 20;
  const passwordError = !password.match(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
  );
  const passwordMatchError = password !== confirmPassword;
  const emailError = !email.match(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  const { palette } = useTheme();
  const getTextColor = (condition: RegExp) =>
    condition.test(password) ? palette.text.secondary : palette.error.main;

  return (
    <form onSubmit={handleRegister} className={classes.root}>
      <Typography variant="h4">Register your user here.</Typography>
      <TextField
        onChange={(event) => setUsername(event.currentTarget.value)}
        label="Username"
        helperText="Between 3 and 20 characters"
        error={usernameError && showErrors}
      />
      <TextField
        onChange={(event) => setPassword(event.currentTarget.value)}
        label="Password"
        type="password"
        helperText={
          <Stack
            alignItems="start"
            sx={{ "& .MuiTypography-root": { margin: 0 } }}
          >
            <Typography variant="body2" color={getTextColor(/\d/)}>
              At least 1 digit
            </Typography>
            <Typography variant="body2" color={getTextColor(/[a-z]/)}>
              Lowercase character
            </Typography>
            <Typography variant="body2" color={getTextColor(/[A-Z]/)}>
              Uppercase character
            </Typography>
            <Typography
              variant="body2"
              color={
                password.length < 6
                  ? palette.error.main
                  : palette.text.secondary
              }
            >
              6 or more characters
            </Typography>
            <Typography variant="body2" color={getTextColor(/\W|_/)}>
              Non-alphanumeric character
            </Typography>
          </Stack>
        }
        error={passwordError && showErrors}
      />
      <TextField
        onChange={(event) => setConfirmPassword(event.currentTarget.value)}
        label="Confirm Password"
        type="password"
        helperText={passwordMatchError ? "Passwords do not match" : ""}
        error={passwordMatchError}
      />
      <TextField
        onChange={(event) => setEmail(event.currentTarget.value)}
        label="Email"
        type="email"
        helperText={emailError ? "Email must be a valid email address" : ""}
        error={emailError && showErrors}
      />
      <LoadingButton
        loading={registerUserMutation.isLoading}
        type="submit"
        variant="contained"
        color="primary"
        disabled={registerUserMutation.isLoading}
      >
        Register
      </LoadingButton>
      <Button onClick={() => navigate("/login")} variant="text">
        Already registered? Click here to log in
      </Button>
    </form>
  );
};

export default Register;
