import { createContext, FC, useState } from "react";
import { useQuery } from "react-query";
import { getCurrentUserWithoutInterceptor } from "../queries/user/getCurrentUser";
import SubscriptionTier from "../types/enums/SubscriptionTier";

export const SubscriptionTierGuardContext = createContext({
  open: false,
  tier: SubscriptionTier.NotChosen,
  currentUserHasTier: (tier: SubscriptionTier, feature: string) =>
    false as boolean,
  hideUpgradeModal: () => {},
  feature: "",
});

const SubscriptionTierGuardProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [tier, setTier] = useState(SubscriptionTier.NotChosen);
  const [feature, setFeature] = useState("");
  const { data: user } = useQuery(
    "current-user",
    getCurrentUserWithoutInterceptor
  );

  const currentUserHasTier = (tier: SubscriptionTier, feature: string) => {
    const currentTier = user?.currentOrganization?.subscriptionTier;
    if (currentTier && currentTier < tier) {
      setOpen(true);
      setTier(tier);
      setFeature(feature);
      return false;
    }
    return true;
  };

  const hideUpgradeModal = () => setOpen(false);

  const context = {
    open,
    tier,
    currentUserHasTier,
    hideUpgradeModal,
    feature,
  };

  return (
    <SubscriptionTierGuardContext.Provider value={context}>
      {children}
    </SubscriptionTierGuardContext.Provider>
  );
};

export default SubscriptionTierGuardProvider;
