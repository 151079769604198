import { SpeedDial, SpeedDialAction } from "@mui/material";
import { FC } from "react";
import range from "../../../utils/range";

const SeasonSelectorSpeedDial: FC<{
  season: number;
  setSeason: (season: number) => void;
  maxSeason: number;
}> = ({ season, setSeason, maxSeason }) => {
  if (maxSeason <= 1) return null;

  return (
    <div style={{ position: "relative", width: "125px", height: "56px" }}>
      <SpeedDial
        ariaLabel="Season Selector"
        icon={"Season " + season}
        sx={{ position: "absolute", bottom: 0, right: 0 }}
        FabProps={{
          variant: "extended",
          color: "secondary",
          sx: { height: "56px" },
        }}
      >
        {range(1, maxSeason + 1).map((s) => (
          <SpeedDialAction
            FabProps={{ variant: "extended" }}
            key={s}
            icon={"Season " + s}
            onClick={() => setSeason(s)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default SeasonSelectorSpeedDial;
