import { Grid, Typography, Button } from "@mui/material";
import { FC } from "react";
import useScorePickerStyles from "./style";

const scores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reverse();

interface IScorePickerProps {
  blackTeam: (string | undefined)[];
  whiteTeam: (string | undefined)[];
  blackScore: number;
  whiteScore: number;
  setBlackScore: (score: number) => void;
  setWhiteScore: (score: number) => void;
}

const ScorePicker: FC<IScorePickerProps> = ({
  blackTeam,
  whiteTeam,
  blackScore,
  setBlackScore,
  whiteScore,
  setWhiteScore,
}) => {
  const classes = useScorePickerStyles();

  return (
    <Grid container>
      <Grid item xs={6} className={classes.scoreList}>
        <Typography variant="h6">Black</Typography>
        {blackTeam.map((playerName) => (
          <Typography variant="body1">{playerName}</Typography>
        ))}
        {scores.map((s) => (
          <Button
            key={s}
            onClick={() => setBlackScore(s)}
            variant={blackScore === s ? "contained" : "text"}
            color="primary"
          >
            {s}
          </Button>
        ))}
      </Grid>
      <Grid item xs={6} className={classes.scoreList}>
        <Typography variant="h6">White</Typography>
        {whiteTeam.map((playerName) => (
          <Typography variant="body1">{playerName}</Typography>
        ))}
        {scores.map((s) => (
          <Button
            key={s}
            onClick={() => setWhiteScore(s)}
            variant={whiteScore === s ? "contained" : "text"}
            color="primary"
          >
            {s}
          </Button>
        ))}
      </Grid>
    </Grid>
  );
};

export default ScorePicker;
