import { Button, Stack, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import downgradeToFreeTier from "../../mutations/organization/downgradeToFreeTier";
import getCurrentUser from "../../queries/user/getCurrentUser";
import logout from "../../queries/authentication/logout";
import http from "../../utils/http";

const InactiveSubscription = () => {
  const { data: user } = useQuery("current-user", getCurrentUser);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const goToStripeCustomerPortal = async () => {
    const res = await http.get("/Stripe/customer-portal");
    window.location.href = res.data;
  };

  const handleLogout = async () => {
    await logout();
    queryClient.invalidateQueries();
    navigate("/login");
  };

  const handleDowngradeToFreeTier = async () => {
    const successfulDowngrade = await downgradeToFreeTier();
    if (successfulDowngrade) {
      queryClient.invalidateQueries("current-user");
      navigate("/home");
    } else {
      navigate("/delete-players-to-downgrade-to-free-tier");
    }
  };

  return (
    <Stack>
      <Typography variant="h3">
        The subscription for {user?.currentOrganization.name} is inactive.
      </Typography>
      <Typography variant="h4">
        Choose one of these options to continue.
      </Typography>
      <Button color="success" onClick={goToStripeCustomerPortal}>
        Update payment information
      </Button>
      <Button color="warning" onClick={handleDowngradeToFreeTier}>
        Downgrade to free tier
      </Button>
      <Button color="error" onClick={handleLogout}>
        Log out
      </Button>
    </Stack>
  );
};

export default InactiveSubscription;
