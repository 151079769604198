import { FC, useState } from "react";
import PregameStats1v1Dto from "../../../dto/PregameStats1v1.dto";
import PregameStats2v2Dto from "../../../dto/PregameStats2v2.dto";
import PlayerPicker from "./PlayerPicker";
import PregameStats from "./PregameStats";
import StartGameButtons from "./StartGameButtons";

const GameStarter: FC = () => {
  const [selected, setSelected] = useState<Set<number>>(
    () => new Set<number>()
  );
  const [locked, setLocked] = useState<Set<number>>(() => new Set<number>());

  const [pregameStats1v1, setPregameStats1v1] = useState<
    PregameStats1v1Dto | undefined
  >();
  const [pregameStats2v2, setPregameStats2v2] = useState<
    PregameStats2v2Dto | undefined
  >();

  const resetPregameStats = () => {
    setPregameStats1v1(undefined);
    setPregameStats2v2(undefined);
    setLocked(new Set<number>());
    setSelected(new Set<number>());
  };

  return pregameStats1v1 || pregameStats2v2 ? (
    <PregameStats
      pregameStats1v1={pregameStats1v1}
      pregameStats2v2={pregameStats2v2}
      newGame={resetPregameStats}
    />
  ) : (
    <>
      <PlayerPicker
        selected={selected}
        locked={locked}
        setSelected={setSelected}
        setLocked={setLocked}
      />
      <StartGameButtons
        selected={selected}
        locked={locked}
        setPregameStats1v1={setPregameStats1v1}
        setPregameStats2v2={setPregameStats2v2}
      />
    </>
  );
};

export default GameStarter;
