import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FC } from "react";
import LoadingButton from "../LoadingButton";
import ScorePicker from "../ScorePicker";
interface ICreateGameModalProps {
  blackScore: number;
  setBlackScore: (score: number) => void;
  whiteScore: number;
  setWhiteScore: (score: number) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  blackTeam: string[];
  whiteTeam: string[];
  loading: boolean;
  handleSave: () => void;
}

// This component should be wrapped and the wrapper should be used outwards
// Use either Create1v1GameModal or Create2v2GameModal
const CreateGameModal: FC<ICreateGameModalProps> = ({
  blackScore,
  setBlackScore,
  whiteScore,
  setWhiteScore,
  open,
  setOpen,
  blackTeam,
  whiteTeam,
  loading,
  handleSave,
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <ScorePicker
          blackScore={blackScore}
          whiteScore={whiteScore}
          setBlackScore={setBlackScore}
          setWhiteScore={setWhiteScore}
          blackTeam={blackTeam}
          whiteTeam={whiteTeam}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGameModal;
