import { FC, useState } from "react";
import { useMutation } from "react-query";
import CreateGameModal from "..";
import PregameStatsDto from "../../../dto/PregameStats2v2.dto";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import createGame from "../../../mutations/game/create2v2Game";
import { getGameResultString } from "../../../types/Game/Game";

const Create2v2GameModal: FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  pregameStats: PregameStatsDto;
}> = ({ open, setOpen, pregameStats }) => {
  const [blackScore, setBlackScore] = useState(0);
  const [whiteScore, setWhiteScore] = useState(0);
  const [loading, setLoading] = useState(false);

  const createGameMutation = useMutation(createGame);
  const { showMessage } = useGlobalSnackbar();

  const handleSave = () => {
    setLoading(true);
    createGameMutation.mutate(
      {
        blackScore,
        whiteScore,
        blackDefenseId: pregameStats.blackDefense.id,
        whiteDefenseId: pregameStats.whiteDefense.id,
        blackOffenseId: pregameStats.blackOffense.id,
        whiteOffenseId: pregameStats.whiteOffense.id,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setOpen(false);
          showMessage(getGameResultString(data), "success", 15000);
        },
        onError: () => {
          setLoading(false);
          showMessage(
            "Game could not be saved, check your internet connection or try again",
            "error",
            15000
          );
        },
      }
    );
  };
  return (
    <CreateGameModal
      open={open}
      setOpen={setOpen}
      blackScore={blackScore}
      setBlackScore={setBlackScore}
      setWhiteScore={setWhiteScore}
      whiteScore={whiteScore}
      blackTeam={[
        pregameStats.blackOffense.name,
        pregameStats.blackDefense.name,
      ]}
      whiteTeam={[
        pregameStats.whiteOffense.name,
        pregameStats.whiteDefense.name,
      ]}
      loading={loading}
      handleSave={handleSave}
    />
  );
};

export default Create2v2GameModal;
