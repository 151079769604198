import {
  Container,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import SubscriptionTier from "../../types/enums/SubscriptionTier";
import SubscriptionTierCard from "./SubscriptionTierCard";

const SelectSubscriptionTier = () => {
  const { breakpoints } = useTheme();
  const largeScreen = useMediaQuery(breakpoints.up("md"));
  const [showYearly, setShowYearly] = useState(false);

  return (
    <Container>
      <Stack spacing={5}>
        <Typography variant={largeScreen ? "h2" : "h3"}>
          Select the subscription that fits you!
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={showYearly}
              onChange={(e) => setShowYearly(e.target.checked)}
            />
          }
          label="Show yearly prices"
        />
        <Stack
          direction={largeScreen ? "row" : "column"}
          justifyContent={"space-around"}
        >
          <SubscriptionTierCard
            tier={SubscriptionTier.Free}
            price={[0, 0]}
            benefits={[
              "Basic game functionality",
              "Basic rating functionality",
              "Up to 5 players",
            ]}
            yearly={showYearly}
          />
          <SubscriptionTierCard
            tier={SubscriptionTier.Premium}
            price={[99, 999]}
            benefits={[
              "Everything in Free tier",
              "Delete and edit games",
              "See and edit player profiles",
              "Up to 20 players",
            ]}
            yearly={showYearly}
          />
          <SubscriptionTierCard
            tier={SubscriptionTier.Pro}
            price={[199, 1999]}
            benefits={[
              "Everything in Premium tier",
              "Achievements",
              "Unlimited players",
            ]}
            yearly={showYearly}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default SelectSubscriptionTier;
