import { makeStyles } from "@mui/styles";

const usePlayerProfileHeaderStyles = makeStyles(() => ({
  header: {
    display: "flex",
    position: "relative",
    height: "60vw",
    maxHeight: "calc(600px * 0.6)",
  },
  headerName: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "7%",
  },
  streakWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    width: "70%",
    zIndex: -2,
    position: "absolute",
    right: "0",
  },
}));

export default usePlayerProfileHeaderStyles;
