import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC } from "react";
import range from "../../../utils/range";

const SeasonSelector: FC<{
  season: number;
  setSeason: (season: number) => void;
  maxSeason: number;
}> = ({ season, setSeason, maxSeason }) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    setSeason(event.target.value as number);
  };

  if (maxSeason <= 1) return null;

  return (
    <FormControl>
      <InputLabel id="season-selector-label">Season</InputLabel>
      <Select
        autoWidth
        labelId="season-selector-label"
        value={season}
        onChange={handleChange}
        label="Season"
      >
        {range(1, maxSeason + 1).map((s) => (
          <MenuItem value={s} key={s}>
            Season {s}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SeasonSelector;
