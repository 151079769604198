import { Routes, Route } from "react-router-dom";
import BottomMenu from "../component/BottomMenu";
import NotFound from "../views/Errors/NotFound";
import LoggedInRouteGuard from "./LoggedInRouteGuard";
import { publicRoutes, restrictedRoutes } from "./routes";

const FoospowerRouter = () => {
  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
      <Route element={<LoggedInRouteGuard />}>
        {restrictedRoutes.map((route) =>
          route.showMenu ? (
            <Route key={route.path + "bottom-menu"} element={<BottomMenu />}>
              <Route key={route.path} {...route} />
            </Route>
          ) : (
            <Route key={route.path} {...route} />
          )
        )}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default FoospowerRouter;
