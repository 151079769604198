import { Typography } from "@mui/material";
import { FC } from "react";
import PlayerListDto from "../../../../types/Player/PlayerListDto";
import PlayerProfile from "../../../../types/Player/PlayerProfile";
import useWinningStreakStyles from "./style";

interface IWinningStreakProps {
  player: PlayerListDto | PlayerProfile;
  large?: boolean;
}

const WinningStreak: FC<IWinningStreakProps> = ({ player, large }) => {
  const classes = useWinningStreakStyles();

  if (player.winningStreak >= 3)
    return (
      <div className={classes.root}>
        <img
          src="/winningStreak.jpg"
          alt="Winning Streak"
          className={classes.image}
        />
        <Typography variant={large ? "h5" : "body1"}>
          {player.winningStreak}
        </Typography>
      </div>
    );

  if (player.winningStreak <= -3)
    return (
      <div className={classes.root}>
        <img
          src="/losingStreak.png"
          alt="Losing Streak"
          className={classes.image}
        />
        <Typography variant={large ? "h5" : "body1"}>
          {player.winningStreak * -1}
        </Typography>
      </div>
    );

  return <></>;
};

export default WinningStreak;
