import { Stack, Typography } from "@mui/material";
import { FC } from "react";

const NotFound: FC = () => {
  return (
    <Stack>
      <Typography variant="h2">404</Typography>
      <Typography variant="h4">Page not found</Typography>
    </Stack>
  );
};

export default NotFound;
