import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, FormEvent, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import LoadingButton from "../../../component/LoadingButton";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import useSubscriptionTierGuard from "../../../hooks/useSubcriptionTierGuard";
import createPlayer from "../../../mutations/player/createPlayer";
import getAllPlayers from "../../../queries/player/getAllPlayers";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import SubscriptionTier from "../../../types/enums/SubscriptionTier";
import useCreatePlayerStyles from "./style";

interface ICreatePlayerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const CreatePlayer: FC<ICreatePlayerProps> = ({ open, setOpen }) => {
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: currentUser } = useQuery(["current-user"], () =>
    getCurrentUser()
  );

  const classes = useCreatePlayerStyles();

  const mutation = useMutation(createPlayer);

  const queryClient = useQueryClient();

  const { data: allPlayers } = useQuery("all-players", () => getAllPlayers());

  const { currentUserHasTier } = useSubscriptionTierGuard();

  const { showMessage } = useGlobalSnackbar();

  const handleCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      allPlayers &&
      (allPlayers?.length < 5 ||
        currentUserHasTier(SubscriptionTier.Premium, "more than 5 players")) &&
      (allPlayers?.length < 20 ||
        currentUserHasTier(SubscriptionTier.Pro, "more than 20 players"))
    ) {
      if (name.length < 2 || name.length > 20)
        return showMessage("Name must be between 2 and 20 characters", "error");

      setLoading(true);
      mutation.mutate(
        {
          name,
          avatar,
        },
        {
          onSuccess: () => {
            setName("");
            setAvatar("");
            setLoading(false);
            setOpen(false);
            queryClient.invalidateQueries([
              "player-list",
              currentUser?.currentOrganization.season,
            ]);
          },
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Add a player</DialogTitle>
      <form onSubmit={handleCreate}>
        <DialogContent className={classes.content}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Avatar Link"
            value={avatar}
            onChange={(e) => setAvatar(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreatePlayer;
