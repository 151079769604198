import { FC, useState } from "react";
import { useMutation } from "react-query";
import CreateGameModal from "..";
import PregameStats1v1Dto from "../../../dto/PregameStats1v1.dto";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import create1v1Game from "../../../mutations/game/create1v1Game";
import { getGameResultString } from "../../../types/Game/Game";

const Create1v1GameModal: FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  pregameStats: PregameStats1v1Dto;
}> = ({ open, setOpen, pregameStats }) => {
  const [blackScore, setBlackScore] = useState(0);
  const [whiteScore, setWhiteScore] = useState(0);
  const [loading, setLoading] = useState(false);

  const createGameMutation = useMutation(create1v1Game);
  const { showMessage } = useGlobalSnackbar();

  const handleSave = () => {
    setLoading(true);
    createGameMutation.mutate(
      {
        blackScore,
        whiteScore,
        blackPlayerId: pregameStats.blackPlayer.id,
        whitePlayerId: pregameStats.whitePlayer.id,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setOpen(false);
          showMessage(getGameResultString(data), "success", 15000);
        },
        onError: () => {
          setLoading(false);
          showMessage(
            "Game could not be saved, check your internet connection or try again",
            "error",
            15000
          );
        },
      }
    );
  };
  return (
    <CreateGameModal
      open={open}
      setOpen={setOpen}
      blackScore={blackScore}
      setBlackScore={setBlackScore}
      setWhiteScore={setWhiteScore}
      whiteScore={whiteScore}
      blackTeam={[pregameStats.blackPlayer.name]}
      whiteTeam={[pregameStats.whitePlayer.name]}
      loading={loading}
      handleSave={handleSave}
    />
  );
};

export default Create1v1GameModal;
