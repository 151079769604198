enum Roles {
  InitGame = -1,
  BlackOffense,
  BlackDefense,
  WhiteOffense,
  WhiteDefense,
  BlackSolo,
  WhiteSolo,
}

export const roleToString = (role: Roles): string => {
  switch (role) {
    case Roles.InitGame:
      return "Setup Game";
    case Roles.BlackOffense:
      return "Black Offense";
    case Roles.BlackDefense:
      return "Black Defense";
    case Roles.WhiteOffense:
      return "White Offense";
    case Roles.WhiteDefense:
      return "White Defense";
    case Roles.BlackSolo:
      return "Black";
    case Roles.WhiteSolo:
      return "White";
    default:
      return "Unknown";
  }
};

export default Roles;
