import { Stack, TextField, IconButton, Button } from "@mui/material";
import { FC, useState } from "react";
import { useQueryClient, useQuery } from "react-query";
import getCurrentUser from "../../../../queries/user/getCurrentUser";
import http from "../../../../utils/http";
import useSlackButtonStyle from "./style";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const RegisterSlackWebhook: FC = () => {
  const queryClient = useQueryClient();
  const { data: user } = useQuery("current-user", getCurrentUser);
  const classes = useSlackButtonStyle();
  const [messages, setMessages] = useState<string[]>(
    user?.currentOrganization.slackConnections.map(
      (connection) => connection.message
    ) || []
  );

  const registerSlackChannel = async () => {
    const response = await http.get("/Slack/webhook-registration-redirect");
    window.location.href = response.data;
  };

  const updateMessage = (index: number, message: string) => {
    const newMessages = [...messages];
    newMessages[index] = message;
    setMessages(newMessages);
  };

  const deleteConnection = async (id: number) => {
    await http.delete(`/Slack/${id}`);
    queryClient.invalidateQueries("current-user");
  };

  const saveMessage = async (id: number, index: number) => {
    await http.put(`/Slack/update-message`, { id, message: messages[index] });
    queryClient.invalidateQueries("current-user");
  };

  return (
    <>
      <Stack spacing={2} width="100%">
        {user?.currentOrganization.slackConnections.map((sc, index) => (
          <Stack direction="row">
            <TextField
              label={"Message in " + sc.channelName}
              defaultValue={sc.message}
              sx={{
                width: "100%",
              }}
              onChange={(e) => updateMessage(index, e.target.value)}
            />
            <IconButton
              color="success"
              onClick={() => saveMessage(sc.id, index)}
            >
              <SaveIcon />
            </IconButton>
            <IconButton color="error" onClick={() => deleteConnection(sc.id)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
      <Button
        variant="contained"
        className={classes.button}
        onClick={registerSlackChannel}
      >
        <img src="/SlackMark.png" alt="Slack Icon" className={classes.image} />
        Register New Slack Channel
      </Button>
    </>
  );
};

export default RegisterSlackWebhook;
