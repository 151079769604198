import { makeStyles } from "@mui/styles";

const useSlackButtonStyle = makeStyles(({ spacing }) => ({
  button: {
    backgroundColor: "#4A154B",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#320c33",
    },
  },
  image: {
    height: "1.5em",
    margin: spacing(1),
  },
}));

export default useSlackButtonStyle;
