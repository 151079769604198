import { FC, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import CreateOrganization from "./CreateOrganization";

const SelectOrganization: FC = () => {
  const { isLoading, data: user } = useQuery("current-user", getCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.currentOrganization) {
      navigate("/home");
    }
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (user?.organizations.length === 0) {
    return <CreateOrganization />;
  }

  return (
    <div>
      <h1>Select Org - When it works atleast</h1>
      {user?.organizations.map((organization) => (
        <p>{organization.organization?.name}</p>
      ))}
    </div>
  );
};

export default SelectOrganization;
