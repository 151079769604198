import makeStyles from "@mui/styles/makeStyles";

const useGameTimerStyles = makeStyles(() => ({
  timerWrapper: {
    marginTop: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  buttonWrapper: {
    marginTop: "50px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  buttons: {
    width: "100px",
  },
}));

export default useGameTimerStyles;
