import qs from "qs";
import PregameStats1v1Dto from "../../dto/PregameStats1v1.dto";
import http from "../../utils/http";

const getPregameStats1v1 = async (
  locked: Set<number>,
  selected: Set<number>
): Promise<PregameStats1v1Dto> => {
  const res = await http.get("game/pregame-stats/1v1", {
    params: {
      lockedPlayerIds: Array.from(locked),
      playerIds: Array.from(selected),
    },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return res.data;
};

export default getPregameStats1v1;
