import { ReactComponent as Sword } from "../../../assets/sword.svg";
import { ReactComponent as Shield } from "../../../assets/shield.svg";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import Game, {
  getPlayersFrom1v1Game,
  getPlayersFrom2v2Game,
} from "../../../types/Game/Game";
import useGameCardStyle from "./style";
import moment from "moment";
import { Delete } from "@mui/icons-material";
import GameType from "../../../types/enums/GameType";

const GameCard: FC<{ game: Game; handleDelete: (gameId: number) => void }> = ({
  game,
  handleDelete,
}) => {
  const classes = useGameCardStyle();

  const { breakpoints } = useTheme();
  const largeScreen = useMediaQuery(breakpoints.up("md"));

  const { whiteOffense, whiteDefense, blackOffense, blackDefense } =
    getPlayersFrom2v2Game(game);

  const { blackPlayer, whitePlayer } = getPlayersFrom1v1Game(game);

  const perfectGame =
    (game.whiteScore === 0 && game.blackScore === 10) ||
    (game.whiteScore === 10 && game.blackScore === 0);
  return (
    <Card
      className={`${classes.card} ${perfectGame ? classes.perfectGame : ""}`}
      elevation={5}
      style={{ width: largeScreen ? "30%" : "95%" }}
    >
      <CardHeader
        subheader={moment.utc(game.created).local().fromNow()}
        action={
          <IconButton size="small" onClick={() => handleDelete(game.id)}>
            <Delete />
          </IconButton>
        }
      />
      <CardContent>
        <div className={classes.mainDiv}>
          <div className={classes.teamWrapper}>
            <span className={classes.teamSpan}>
              {game.type === GameType.OneVsOne ? (
                <span>{whitePlayer?.player.name}</span>
              ) : (
                <>
                  <Sword className={classes.roleIcons} />
                  <span>{whiteOffense?.player.name}</span>
                </>
              )}
            </span>
            {game.type === GameType.TwoVsTwo && (
              <span className={classes.teamSpan}>
                <Shield className={classes.roleIcons} />
                <span>{whiteDefense?.player.name}</span>
              </span>
            )}
          </div>
          <div className={classes.scoreWrapper}>
            <span>
              {game.whiteScore} - {game.blackScore}
            </span>
            <span>{game.rating}</span>
          </div>
          <div className={`${classes.teamWrapper} ${classes.blackTeamWrapper}`}>
            <span className={`${classes.teamSpan} ${classes.blackTeamSpan}`}>
              {game.type === GameType.OneVsOne ? (
                <span>{blackPlayer?.player.name}</span>
              ) : (
                <>
                  <span>{blackDefense?.player.name}</span>
                  <Shield className={classes.roleIcons} />
                </>
              )}
            </span>
            {game.type === GameType.TwoVsTwo && (
              <span className={`${classes.teamSpan} ${classes.blackTeamSpan}`}>
                <span>{blackOffense?.player.name}</span>
                <Sword className={classes.roleIcons} />
              </span>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default GameCard;
