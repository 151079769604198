import { makeStyles } from "@mui/styles";

const usePlayerProfileStyles = makeStyles(() => ({
  root: {
    maxWidth: "600px",
    margin: "auto",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30vh",
  },
  information: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    width: "100%",
    marginBottom: "100px",
  },
}));

export default usePlayerProfileStyles;
