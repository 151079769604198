import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import setPreferredGameTypes from "../../../mutations/organization/setPreferredGameTypes";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import GameType from "../../../types/enums/GameType";

const PreferredGameTypePicker: FC<{ hideText?: boolean }> = ({
  hideText = false,
}) => {
  const { data: user } = useQuery("current-user", getCurrentUser);
  const setPreferredGameTypesMutation = useMutation(setPreferredGameTypes, {
    onSuccess: () => showMessage("Game type preferance has been updated"),
    onError: (error: AxiosError) => showMessage(error.response?.data, "error"),
  });
  const queryClient = useQueryClient();
  const { showMessage } = useGlobalSnackbar();

  const org = user?.currentOrganization;

  const [gameTypes, setGameTypes] = useState<number[]>(
    org?.preferredGameTypes ?? []
  );

  const handleChange = (e: SelectChangeEvent<number[]>) => {
    setGameTypes(e.target.value as number[]);
  };

  const handleSave = () => {
    setPreferredGameTypesMutation.mutate(gameTypes);
    queryClient.invalidateQueries("current-user");
  };

  return (
    <Stack>
      {!hideText && (
        <Typography variant="h5">Select preferred game types</Typography>
      )}
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="select-preferred-game-type-label">
          Preferred game types
        </InputLabel>
        <Select
          sx={{ width: "100%" }}
          labelId="select-preferred-game-type-label"
          id="preferred-game-type-select"
          multiple
          value={gameTypes}
          onChange={handleChange}
          input={
            <OutlinedInput
              id="preferred-game-type-input"
              label="Preferred game types"
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={GameType[value]} />
              ))}
            </Box>
          )}
        >
          <MenuItem value={GameType.OneVsOne}>
            {GameType[GameType.OneVsOne]}
          </MenuItem>
          <MenuItem value={GameType.TwoVsTwo}>
            {GameType[GameType.TwoVsTwo]}
          </MenuItem>
        </Select>
      </FormControl>
      <Button onClick={handleSave}>Save</Button>
    </Stack>
  );
};

export default PreferredGameTypePicker;
