import { AppBar, Box, Tab, Tabs } from "@mui/material";
import { Home, List, Person, Settings } from "@mui/icons-material";
import { FC } from "react";
import useStyles from "./style";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const BottomMenu: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const isValidTab = ["/home", "/players", "/games", "/settings"].includes(
    location.pathname
  );

  return (
    <Box sx={{ marginBottom: "80px" }}>
      <Outlet />
      <AppBar className={classes.appBar}>
        <Tabs
          value={isValidTab && location.pathname}
          onChange={(e, value: string) => {
            navigate(value);
          }}
          variant="fullWidth"
          TabIndicatorProps={{
            style: { bottom: "auto", top: 0 },
          }}
          indicatorColor="secondary"
          textColor="inherit"
        >
          <Tab label={<Home fontSize="large" />} value={"/home"} />
          <Tab label={<Person fontSize="large" />} value={"/players"} />
          <Tab label={<List fontSize="large" />} value={"/games"} />
          <Tab label={<Settings fontSize="large" />} value={"/settings"} />
        </Tabs>
      </AppBar>
    </Box>
  );
};

export default BottomMenu;
