import makeStyles from "@mui/styles/makeStyles";

const useCreatePlayerStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "10px !important",
  },
}));

export default useCreatePlayerStyles;
