import { Button, TextField, Typography } from "@mui/material";
import { FC, FormEvent, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../component/LoadingButton";
import useGlobalSnackbar from "../../hooks/useGlobalSnackbar";
import login from "../../mutations/authentication/login";
import isLoggedIn from "../../queries/authentication/isLoggedIn";
import User from "../../types/User";
import useRegisterStyles from "../Register/style";

const Login: FC = () => {
  const classes = useRegisterStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const loginMutation = useMutation(login);

  const { showMessage } = useGlobalSnackbar();

  useEffect(() => {
    async function checkLogin() {
      const loggedIn = await isLoggedIn();
      if (loggedIn) {
        navigate("/home");
      }
    }
    checkLogin();
  }, [navigate]);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    loginMutation.mutate(
      {
        username,
        password,
      },
      {
        onSuccess: (user: User) => {
          navigate(
            !!user.currentOrganizationId ? "/home" : "/select-organization"
          );
        },
        onError: (error: any) => {
          showMessage("Could not log in. Please check credentials.", "error");
        },
      }
    );
  };

  return (
    <form onSubmit={handleLogin} className={classes.root}>
      <Typography variant="h4">Login</Typography>
      <TextField
        onChange={(event) => setUsername(event.currentTarget.value)}
        label="Username"
      />
      <TextField
        onChange={(event) => setPassword(event.currentTarget.value)}
        label="Password"
        type="password"
      />
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        loading={loginMutation.isLoading}
      >
        Login
      </LoadingButton>
      <Button onClick={() => navigate("/register")} variant="text">
        Not registered? Click here to register
      </Button>
    </form>
  );
};

export default Login;
