import { makeStyles } from "@mui/styles";

const useScorePickerStyles = makeStyles(() => ({
  scoreList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "130px",
  },
}));

export default useScorePickerStyles;
