import { makeStyles } from "@mui/styles";

const useManualGameStyles = makeStyles(({ spacing }) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
  },
}));

export default useManualGameStyles;
