import { SpeedDial, SpeedDialAction } from "@mui/material";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useGlobalSnackbar from "../../../hooks/useGlobalSnackbar";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import http from "../../../utils/http";
import useSendSlackMessageStyles from "./style";

const SendSlackMessage: FC = () => {
  const { data: user } = useQuery("current-user", getCurrentUser);
  const classes = useSendSlackMessageStyles();
  const [loading, setLoading] = useState(false);
  const { showMessage } = useGlobalSnackbar();
  const navigate = useNavigate();

  const sendSlackMessage = async (id: number) => {
    if (!loading) {
      setLoading(true);
      await http.post(`/Slack/send-message/${id}`);
      showMessage("Message sent successfully", "success");
      setLoading(false);
    }
  };

  const registerSlackChannel = async () => {
    if (user?.currentOrganization.slackConnections.length === 0)
      navigate("/settings");
  };

  if (!user) return null;

  return (
    <>
      {user?.currentOrganization?.slackConnections && (
        <SpeedDial
          ariaLabel="Slack Speed Dial"
          className={classes.fab}
          icon={<img src="/SlackMark.png" alt="Slack logo" width="50%" />}
          onClick={registerSlackChannel}
        >
          {user.currentOrganization.slackConnections.map((sc) => (
            <SpeedDialAction
              key={sc.id}
              tooltipTitle={sc.channelName}
              tooltipOpen
              icon={<img src="/SlackMark.png" alt="Slack logo" width="50%" />}
              onClick={() => sendSlackMessage(sc.id)}
            />
          ))}
        </SpeedDial>
      )}
    </>
  );
};

export default SendSlackMessage;
