import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setupInterceptors } from "./http";

const InjectAxiosInterceptors = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setupInterceptors(navigate);
  });

  return null;
};

export default InjectAxiosInterceptors;
