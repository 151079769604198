function range(_p: number, _t?: number, _s?: number): Array<number> {
  /**
   * @param <_p> Return a list integers of zero until <_p> value.
   * @param <_t> Return a list integers of <_t> until <_p> value.
   * @param <_s> Return a list integers of <_t> until <_p> with steps <_s> value.
   * @return Return a array list
   */

  let start: number = _t ? _p : 0;
  let stop: number = _t ? _t : _p;
  let step: number = _s ? _s : 1;

  let t: Array<number> = [];
  for (let i = start; i < stop; i = i + step) {
    t.push(i);
  }

  return t;
}

export default range;
