import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { FC } from "react";

interface ILoadingButtonProps extends ButtonProps {
  loading: boolean;
}

const LoadingButton: FC<ILoadingButtonProps> = (props) => {
  return (
    <Button {...props} disabled={props.loading}>
      {props.loading ? (
        <CircularProgress color="secondary" size={24} />
      ) : (
        <>{props.children}</>
      )}
    </Button>
  );
};

export default LoadingButton;
