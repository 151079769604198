import axios from "axios";
import User from "../../types/User";
import http from "../../utils/http";

const getCurrentUser = async (): Promise<User> => {
  const res = await http.get("user/current");
  return res.data;
};

export const getCurrentUserWithoutInterceptor = async (): Promise<User> => {
  const cleanAxios = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    withCredentials: true,
  });
  const res = await cleanAxios.get("user/current");
  return res.data;
};

export default getCurrentUser;
