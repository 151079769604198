import { Grid } from "@mui/material";
import { FC } from "react";
import PlayerChip from "../../../../../component/PlayerChip";
import PregameStats2v2Dto from "../../../../../dto/PregameStats2v2.dto";
import Roles from "../../../../../types/enums/Roles";

const TwoVsTwoPregameGrid: FC<{ stats: PregameStats2v2Dto }> = ({ stats }) => {
  return (
    <>
      <Grid item xs={6}>
        <PlayerChip player={stats.blackDefense} role={Roles.BlackDefense} />
      </Grid>
      <Grid item xs={6}>
        <PlayerChip player={stats.whiteOffense} role={Roles.WhiteOffense} />
      </Grid>
      <Grid item xs={6}>
        <PlayerChip player={stats.blackOffense} role={Roles.BlackOffense} />
      </Grid>
      <Grid item xs={6}>
        <PlayerChip player={stats.whiteDefense} role={Roles.WhiteDefense} />
      </Grid>
    </>
  );
};

export default TwoVsTwoPregameGrid;
