import Create2v2GameDto from "../../dto/Create2v2Game.dto";
import Game from "../../types/Game/Game";
import http from "../../utils/http";

const create2v2Game = async (
  createGameDto: Create2v2GameDto
): Promise<Game> => {
  const res = await http.post("/game/2v2", createGameDto);
  return res.data;
};

export default create2v2Game;
