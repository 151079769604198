import makeStyles from "@mui/styles/makeStyles";

const useWinningStreakStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
  },
  image: {
    width: "18px",
    height: "25px",
  },
}));

export default useWinningStreakStyles;
