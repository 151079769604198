import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import getCurrentUser from "../../../queries/user/getCurrentUser";
import StartNewSeasonDialog from "./StartNewSeasonDialog";

const SeasonSettings: FC = () => {
  const { isLoading, data: user } = useQuery("current-user", getCurrentUser);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Card>
        <CardContent>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Stack>
              <Typography variant="h5">
                Currently in <b>Season {user?.currentOrganization.season}</b>
              </Typography>
              <Button
                color="warning"
                onClick={() => setOpenModal((currentlyOpen) => !currentlyOpen)}
              >
                Start new season
              </Button>
            </Stack>
          )}
        </CardContent>
      </Card>
      <StartNewSeasonDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default SeasonSettings;
