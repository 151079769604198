import { Stack } from "@mui/material";
import { FC } from "react";
import LogoutButton from "../../component/LogoutButton";
import OrganizationSettings from "./OrganizationSettings";
import PreferredGameTypePicker from "./PreferredGameTypePicker";
import SeasonSettings from "./SeasonSettings";
import UserSettings from "./UserSettings";

const Settings: FC = () => {
  return (
    <Stack justifyContent="space-around" spacing={6}>
      <Stack
        direction="column"
        justifyContent="space-around"
        spacing={2}
        sx={{ padding: "20px" }}
      >
        <UserSettings />
        <OrganizationSettings />
        <PreferredGameTypePicker />
        <SeasonSettings />
      </Stack>
      <LogoutButton />
    </Stack>
  );
};

export default Settings;
