import { makeStyles } from "@mui/styles";

const usePlayerProfileGamesStyle = makeStyles(() => ({
  roleIcons: {
    height: "16px",
    opacity: 0.75,
    padding: "0 5px",
  },
}));

export default usePlayerProfileGamesStyle;
