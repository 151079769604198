import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import defaultTheme from "./theme/defaultTheme";
import FoospowerRouter from "./router/router";
import { BrowserRouter } from "react-router-dom";
import InjectAxiosInterceptors from "./utils/InjectAxiosInterceptors";
import GlobalSnackbar from "./component/GlobalSnackbar";
import SnackbarProvider from "./providers/SnackbarProvider";
import { ReactQueryDevtools } from "react-query/devtools";
import SubscriptionTierGuardProvider from "./providers/SubscriptionTierGuardProvider";
import SubscriptionTierGuard from "./component/SubscriptionTierGuard";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <SnackbarProvider>
        <GlobalSnackbar />
        <BrowserRouter>
          <InjectAxiosInterceptors />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
              <SubscriptionTierGuardProvider>
                <SubscriptionTierGuard />
                <FoospowerRouter />
              </SubscriptionTierGuardProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
