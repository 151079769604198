import { Chip, Avatar } from "@mui/material";
import { FC } from "react";
import Roles from "../../types/enums/Roles";
import usePlayerChipStyles from "./style";
import PlayerPickerPlayer from "../../types/Player/PlayerPickerPlayer";

interface IPlayerChipProps {
  player: PlayerPickerPlayer;
  isSelected?: boolean;
  isLocked?: boolean;
  handleClick?: (player: PlayerPickerPlayer) => void;
  role?: Roles;
}

const PlayerChip: FC<IPlayerChipProps> = ({
  player,
  isSelected,
  isLocked,
  handleClick,
  role,
}) => {
  const isWhiteTeam =
    role !== undefined &&
    (role === Roles.WhiteDefense || role === Roles.WhiteOffense);
  const isBlackTeam =
    role !== undefined &&
    (role === Roles.BlackDefense || role === Roles.BlackOffense);

  const teamIsSelected = isWhiteTeam || isBlackTeam;

  const classes = usePlayerChipStyles({ teamIsSelected, isSelected, isLocked });

  const label = (
    <div
      className={isWhiteTeam || isBlackTeam ? classes.includeRole : undefined}
    >
      {isWhiteTeam && <div>{role === Roles.WhiteDefense ? "WD" : "WO"}</div>}
      <div>{player.name}</div>
      {isBlackTeam && <div>{role === Roles.BlackDefense ? "BD" : "BO"}</div>}
    </div>
  );

  // If team is not selected show number of games last hour instead of profile image
  // Profile image is still shown if user has 0 games last hour
  const avatar = () => {
    if (!teamIsSelected) {
      if (player.gamesPlayedLastHour > 0) {
        return (
          <Avatar className={classes.avatar}>
            <span className={classes.avatarGameCount}>
              {player.gamesPlayedLastHour}
            </span>
          </Avatar>
        );
      }

      return (
        <Avatar src={player.avatar} className={classes.avatar}>
          {player.name[0]}
        </Avatar>
      );
    }
  };

  return (
    <Chip
      avatar={avatar()}
      label={label}
      className={classes.chip}
      onClick={() => !!handleClick && handleClick(player)}
    />
  );
};

export default PlayerChip;
