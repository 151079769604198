import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import PlayerChip from "../../../../component/PlayerChip";
import OneVsOnePregameStatsDto from "../../../../dto/PregameStats1v1.dto";
import PregameStatsDto from "../../../../dto/PregameStats2v2.dto";
import GameType from "../../../../types/enums/GameType";
import Create1v1GameModal from "../../../../component/CreateGameModal/Create1v1GameModal";
import GameTimer from "./GameTimer";
import OneVsOnePregameGrid from "./OneVsOnePregameGrid";
import usePregameStatsStyles from "./style";
import TwoVsTwoPregameGrid from "./TwoVsTwoPregameGrid";
import Create2v2GameModal from "../../../../component/CreateGameModal/Create2v2GameModal";

interface IPregameStatsProps {
  pregameStats1v1?: OneVsOnePregameStatsDto;
  pregameStats2v2?: PregameStatsDto;
  newGame: () => void;
}

const PregameStats: FC<IPregameStatsProps> = ({
  pregameStats1v1,
  pregameStats2v2,
  newGame,
}) => {
  const stats = pregameStats1v1 ?? pregameStats2v2;
  const gameType = pregameStats1v1 ? GameType.OneVsOne : GameType.TwoVsTwo;

  const classes = usePregameStatsStyles();

  const [openModal, setOpenModal] = useState(false);

  const blackWinBy = stats?.ratingInformation.predictedBlackLead ?? 0;

  if (stats)
    return (
      <>
        <div style={{ padding: "5px" }}>
          <Grid container spacing={1}>
            <Grid item xs={5} className={classes.score}>
              {stats?.ratingInformation.ratingChange.blackWin}
            </Grid>
            <Grid item xs={2} className={classes.score}>
              {blackWinBy > 0 && <ArrowBack />}
              {Math.abs(blackWinBy)}
              {blackWinBy < 0 && <ArrowForward />}
            </Grid>
            <Grid item xs={5} className={classes.score}>
              {stats?.ratingInformation.ratingChange.whiteWin}
            </Grid>
            {gameType === GameType.OneVsOne && (
              <OneVsOnePregameGrid stats={pregameStats1v1!} />
            )}
            {gameType === GameType.TwoVsTwo && (
              <TwoVsTwoPregameGrid stats={pregameStats2v2!} />
            )}
          </Grid>
          {stats.outPlayers && stats.outPlayers.length > 0 && (
            <>
              <Typography
                variant="h6"
                style={{ textAlign: "center", marginTop: "16px" }}
              >
                Out:
              </Typography>
              <Grid container spacing={1}>
                {stats.outPlayers.map((player) => (
                  <Grid item xs={4}>
                    <PlayerChip player={player} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              Record Score
            </Button>
            <Button color="secondary" variant="contained" onClick={newGame}>
              New Game
            </Button>
          </div>
        </div>
        <GameTimer />
        {gameType === GameType.OneVsOne ? (
          <Create1v1GameModal
            open={openModal}
            setOpen={setOpenModal}
            pregameStats={pregameStats1v1!}
          />
        ) : (
          <Create2v2GameModal
            open={openModal}
            setOpen={setOpenModal}
            pregameStats={pregameStats2v2!}
          />
        )}
      </>
    );

  return <Typography>Could not get pregameStats</Typography>;
};

export default PregameStats;
