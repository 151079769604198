import { TextField } from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../../../component/LoadingButton";
import createOrganization from "../../../../mutations/organization/createOrganization";
import useCreateOrganizationStyle from "./style";

const CreateOrganization: FC = () => {
  const [name, setName] = useState("");
  const mutation = useMutation(createOrganization);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const classes = useCreateOrganizationStyle();

  const handleCreate = () => {
    mutation.mutate(
      { name },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("current-user");
          navigate("/home");
        },
      }
    );
  };

  return (
    <form onSubmit={handleCreate} className={classes.root}>
      <h2>You are not connected to any organizations, please create one:</h2>
      <TextField
        label="Organization Name"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <LoadingButton
        loading={mutation.isLoading}
        variant="contained"
        onClick={handleCreate}
        disabled={name.length < 3}
      >
        Create Organization
      </LoadingButton>
    </form>
  );
};

export default CreateOrganization;
