import PlayerGameRole from "../PlayerGameRole";
import Roles from "../enums/Roles";
import GameResult from "../enums/GameResult";
import PersonalGameResult from "../enums/PersonalGameResult";
import GameType from "../enums/GameType";

export default interface Game {
  id: number;
  rating: number;
  blackScore: number;
  whiteScore: number;
  result: GameResult;
  players: PlayerGameRole[];
  created: Date;
  type: GameType;
}

export const getGameResultString = (game: Game): string => {
  if (game.result === GameResult.Draw) return getDrawString(game);

  return getGameWinnerString(game);
};

const getDrawString = (game: Game): string => {
  const blackGainedMostPoints = game.rating > 0;
  const gainedPointsPlayers = game.players
    .filter(
      (p) =>
        (p.isBlackTeam && blackGainedMostPoints) ||
        (p.isWhiteTeam && !blackGainedMostPoints)
    )
    .map((p) => p.player.name);

  return `The game was a draw. ${gainedPointsPlayers.join(
    " & "
  )} gained ${Math.abs(game.rating)} points`;
};

const getGameWinnerString = (game: Game): string => {
  const players = game.players
    .filter((p) => p.result === PersonalGameResult.Won)
    .map((p) => p.player.name);
  const score =
    game.result === GameResult.BlackWin
      ? `${game.blackScore} - ${game.whiteScore}`
      : `${game.whiteScore} - ${game.blackScore}`;
  return `${players.join(" & ")} won ${score} and gained ${game.rating} points`;
};

export const getPlayersFrom2v2Game = (game: Game) => {
  const whiteOffense = game.players.find((p) => p.role === Roles.WhiteOffense);
  const whiteDefense = game.players.find((p) => p.role === Roles.WhiteDefense);
  const blackOffense = game.players.find((p) => p.role === Roles.BlackOffense);
  const blackDefense = game.players.find((p) => p.role === Roles.BlackDefense);

  const winnerTeamPlayers =
    game.result === GameResult.BlackWin
      ? [blackOffense, blackDefense]
      : [whiteOffense, whiteDefense];
  const loserTeamPlayers =
    game.result === GameResult.BlackWin
      ? [whiteOffense, whiteDefense]
      : [blackOffense, blackDefense];

  return {
    whiteOffense,
    whiteDefense,
    blackOffense,
    blackDefense,
    winnerTeamPlayers,
    loserTeamPlayers,
  };
};

export const getPlayersFrom1v1Game = (game: Game) => {
  const blackPlayer = game.players.find((p) => p.role === Roles.BlackSolo);
  const whitePlayer = game.players.find((p) => p.role === Roles.WhiteSolo);

  return {
    blackPlayer,
    whitePlayer,
  };
};
